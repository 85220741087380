import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";
import {Image, Loader, Modal} from "semantic-ui-react";
import PAButtonBase from "./pa-button-base";
import {getUA} from "react-device-detect";
import {QRModal2} from "./hkalipay2/lib/qrmodal2";

class CreditPurchaseButtonHkalipay2 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            open: false,
            open2: false,
            qrCodeUrl: "",
            transactionId: "",
        };
    }

    onClose() {
        this.setState({
            loading: false,
            open: false,
            open2: false
        });
    }

    handleClick() {
        if(this.props.disabled) {
            return;
        }
        this.setState({
            loading: true
        });
        new KaisakuUserApi(this.props.session).createTransactionHkalipay2(this.props.product.productId, this.props.paymentAgency, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
            if(res.status === 200) {
                const { msg, payUrl, code, transactionId} = res.data;
                if (msg === "success") {
                    console.log(payUrl);
                    let GotoPayEndpoint = "https://www.newguards.net/wp-json/ggsdk/v1/gotopay?url=" + payUrl;
                    if (process.env.apiName === "prod-jgg-api") {
                        GotoPayEndpoint = "https://www.newguards.net/wp-json/ggsdk/v2/gotopay?url=" + payUrl;
                    }
                    window.location.href = GotoPayEndpoint;
                }else{
                    navigate(`/credit-purchase-result/?success=${false}&message=${res.data.msg}`)
                }
            } else {
                if ((res.data.message.indexOf("支付忙碌") !== -1) || (res.data.message.indexOf("頻繁操作") !== -1)){
                    console.log(res.data.message);
                    this.setState({
                        loading: false,
                        open2: true,
                        message: res.data.message
                    });
                }else{
                    navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
                }
            }
        });
    }

    render() {
        const gameId1 = "aac53ce9-f03c-4e71-953b-df9ec022a837";
        const gameId2 = "aac53ce9-f03c-4e71-953b-df9ec022a837";
        const gameId3 = "b15402f9-6bca-4c17-abed-5ba00f7dd0c7";
        const gameId4 = "de7679d9-c5b5-47ae-84f5-ad96be7d612e";
        const gameId5 = "f552d32d-f73a-46c6-ba20-42098ed27c1c";
        const gameId6 = "87cd854c-f5f4-4018-a57d-6d49007931db";

        const gameId = this.props.gameId;
        let buttondisabled = this.props.disabled;
        // if (((gameId1 === gameId) || (gameId2 === gameId) || (gameId3 === gameId) || (gameId4 === gameId)
        // || (gameId5 === gameId) || (gameId6 === gameId))){;
        //     buttondisabled = true;
        // }

        return (
            <Fragment>
            <Image price={this.props.product.prices[this.props.currency]}
        src={this.props.image}
        disabled={buttondisabled}
                          hidden={buttondisabled}
        onClick={this.handleClick.bind(this)}/>
        <QRModal2 onClose={this.onClose.bind(this)} open={this.state.open2}  message={this.state.message}/> 
        </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonHkalipay2);

CreditPurchaseButtonHkalipay2.propTypes = {
    image: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};