import React, {Component, createRef, Fragment} from "react";
import {Form} from "semantic-ui-react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import PAButtonBase from "./pa-button-base";
import PropTypes from 'prop-types';
import {QRModal2} from "./wechatpay/lib/qrmodal2";
import {Image, Loader, Modal} from "semantic-ui-react";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import asiaunionpay3 from "./asia/images/asiaunionpay4.png";
import asiaunionpay3_95 from "./asia/images/asiaunionpay4_95.png";

class CreditPurchaseButtonPaydollar extends Component {
    constructor(props, context) {
        super(props, context);
        this.trigger = createRef();
        this.state = {
            loading: false,
            open: false,
            clicked: false,
            dataMessage: ""
        };
    }

    onClose() {
        this.setState({
            loading: false,
            open: false,
        });
    }

    handleClick() {
        if(this.state.clicked) {
            console.log("Already clicked!");
            return;
        }
        this.setState({
            clicked: true
        });
        new KaisakuUserApi(this.props.session).createTransactionPaydollar(this.props.product.productId, this.props.paymentAgency, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
            if(res.status === 200) {
                const {merchantId, amount, orderRef, successUrl, failUrl, cancelUrl, secureHash} = res.data;
                console.log(res.data);
                this.setState({
                    merchantId,
                    amount,
                    orderRef,
                    successUrl,
                    failUrl,
                    cancelUrl,
                    secureHash
                });
                this.trigger.current.click();
            }else {
                if ((res.data.message.indexOf("支付忙碌") !== -1) || (res.data.message.indexOf("頻繁操作") !== -1)){
                    console.log(res.data.message);
                    this.setState({
                        loading: false,
                        open: true,
                        message: res.data.message
                    });
                }else{
                    navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
                }
            }
        });
    }

    render() {
        const gameId = this.props.gameId;
        let buttondisabled = this.props.disabled;
        // if (((gameId1 === gameId) || (gameId2 === gameId) || (gameId3 === gameId) || (gameId4 === gameId)
        //     || (gameId5 === gameId) || (gameId6 === gameId))){;
        //     buttondisabled = true;
        // }
        let image = this.props.image;
        const list = [
            "6c7b59f2-5922-411b-0729-5332c0ddcb02",
            "6c7b59f2-5922-411b-0729-5332c0ddea02",
            "d1747cc0-b347-46ba-0729-680011223302",
            "ae5eb5b7-fcfc-4628-0729-749676c22702",
            "ae5eb5b7-fcfc-4628-0729-749676c25b02",
            "74f12a51-18c4-488a-0729-ca0e73348d02",
            "64b51ae5-24c0-42db-97a4-eaf444160002",
            "ed744519-0c1b-43a0-8704-2b3b075f0002",
            "a043fc6e-e0de-4f96-8d5c-2fdbc6090002",

            "47bcf735-6d24-4100-0729-c0e50b74b502",
            "5264a4c9-5a8f-47e3-0729-c4d9c0ddfe02",
            "f01f1a69-d7d4-4255-0729-7f06a0904a02",
            "6c7b59f2-5922-411b-0729-5332c0ddcb02",
            "ae5eb5b7-fcfc-4628-0729-749676c22702",
            "2ee475a6-e327-402a-0729-b3c9d182de02",
            "8026d567-d19f-4507-bedd-e6c6e3190002",
            "28dce5a7-2029-446a-90dc-c31ad1410002",
            "c83083e6-edf6-4bd6-bd7e-027a51d00002",

            "d2f871fa-485e-4953-2022-c434df7b8f6e",
            "149a7e09-7c8e-4601-2022-6a80f453ada0",
            "40ddc41c-48ab-42bd-2022-f3e1bd1ff6f5",
            "7189ffe3-b4dc-4d7f-2022-63230c784d37",
            "81d0d75c-d00c-4aa1-2022-39bf7b0d2608",
            "46b7044e-6d24-4de3-2022-a8aad45d0aa0"

            ];
        if (
            list.includes(this.props.product.productId)
           ){
            image = asiaunionpay3; 
        }
        return (
            <Fragment>
<Form name="payFormCcard" method="post" action="https://www.paydollar.com/b2c2/eng/payment/payForm.jsp">
<input type="hidden" name="merchantId" value={this.state.merchantId}/>
<input type="hidden" name="amount" value={this.state.amount}/>
<input type="hidden" name="orderRef" value={this.state.orderRef}/>
<input type="hidden" name="currCode" value="344"/>
<input type="hidden" name="mpsMode" value="NIL"/>
<input type="hidden" name="successUrl" value={this.state.successUrl}/>
<input type="hidden" name="failUrl" value={this.state.failUrl}/>
<input type="hidden" name="cancelUrl" value={this.state.cancelUrl}/>
<input type="hidden" name="payType" value="N"/>
<input type="hidden" name="lang" value="C"/>
<input type="hidden" name="payMethod" value="CHINAPAY"/>
<input type="hidden" name="secureHash" value={this.state.secureHash}/>
<input type="submit" style={{display: "none"}}
                           ref={this.trigger}/>
</Form>
                <Image price={this.props.product.prices[this.props.currency]}
                              src={image}
                              disabled={this.props.disabled}
                              hidden={this.props.disabled}
                              onClick={this.handleClick.bind(this)}/>
                              <QRModal2 onClose={this.onClose.bind(this)} open={this.state.open}  message={this.state.message}/> 
            </Fragment>
        );

    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonPaydollar);

CreditPurchaseButtonPaydollar.propTypes = {
    image: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};