import React, {Component} from "react";
import {navigate} from "../../../../../../.cache/gatsby-browser-entry";
import KaisakuApi from "../../../../../controllers/kaisaku-api";
import {Button, Dimmer, Header, Image, Loader, Modal} from "semantic-ui-react";
import {secondsToString} from "../../../../../lib/time-util";

export class QRModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            expireIn: 300,
            retryIn: 3
        };
    }

    onOpen() {
        this.startExpireCountdown();
        this.resetQueryTimer();
    }

    cleanupTimers() {
        clearInterval(this.queryTimer);
        clearInterval(this.expireTimer);
    }

    startExpireCountdown() {
        this.setState({expireIn: 300});
        this.expireTimer = setInterval(() => {
            let {expireIn} = this.state;
            console.log(--expireIn);
            this.setState({expireIn});
            // Expire only if there isn't an ongoing api query
            if(expireIn <= 0 && !this.state.loading) {
                navigate(`credit-purchase-result/?transactionId=${this.props.transactionId}&success=${false}&message=${"ERROR.TRANSACTION_EXPIRED"}`);
            }
        }, 1000);
    }

    resetQueryTimer() {
        clearInterval(this.queryTimer);
        this.setState({retryIn: 3});
        this.queryTimer = setInterval(async () => {
            let {retryIn} = this.state;
            console.log(--retryIn);
            this.setState({retryIn});
            if(retryIn <= 0) {
                clearInterval(this.queryTimer);
                const status = await this.getOrderStatus();
                this.processStatus(status);
            }
        }, 1000);
    }

    processStatus(status) {
        console.log(status);
        switch(status) {
            case "Created":
                this.resetQueryTimer();
                break;
            case "Confirmed":
                if(this.confirmed) {
                    navigate(`credit-purchase-result/?transactionId=${this.props.transactionId}&success=${true}`);
                } else {
                    this.resetQueryTimer();
                    this.confirmed = true;
                }
                break;
            case "Completed":
                navigate(`credit-purchase-result/?transactionId=${this.props.transactionId}&success=${true}`);
                break;
            default:
                break;
        }
    }

    componentWillUnmount() {
        this.cleanupTimers();
    }

    async getOrderStatus() {
        const {transactionId} = this.props;
        this.setState({loading: true});
        const {transaction: {status}} = await KaisakuApi.getCreditTransactionStatus(transactionId);
        this.setState({loading: false});
        return status;
    }

    render() {
        return (
            <Modal
                size={"mini"}
                open={this.props.open}
                onUnmount={this.cleanupTimers.bind(this)}
                onMount={this.onOpen.bind(this)}
                style={{width:"90vw"}}
            >
                <Modal.Header>微信二維碼</Modal.Header>
                <Modal.Content image>
                    <Dimmer active={this.state.loading}>
                        <Loader content={"更新中..."}/>
                    </Dimmer>
                    <Image wrapped size='medium'
                           src={this.props.qrCodeUrl}/>
                    <Modal.Description>
                        <Header>掃碼支付後將會自動跳轉</Header>
                        <p>
                            二維碼將於以下時間後超時:
                        </p>
                        <p>{secondsToString(this.state.expireIn)}</p>
                    </Modal.Description>
                    <Modal.Actions>
                        <Button
                            negative
                            onClick={() => {
                                this.cleanupTimers();
                                this.props.onClose();
                                navigate(`/credit-purchase-result/?success=${false}&message=${encodeURI('用戶取消')}`)
                            }}>取消支付</Button>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        );
    }
}