import React, {Component, createRef, Fragment} from "react";
import {Form} from "semantic-ui-react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import PAButtonBase from "./pa-button-base";
import PropTypes from "prop-types";

class CreditPurchaseButtonTwGash extends Component {
    constructor(props, context) {
        super(props, context);
        this.trigger = createRef();
        this.state = {
            clicked: false,
            dataMessage: ""
        };
    }

    handleClick() {
        if(this.state.clicked) {
            console.log("Already clicked!");
            return;
        }
        this.setState({
            clicked: true
        });
        new KaisakuUserApi(this.props.session).createTransactionTwGash(this.props.product.productId, this.props.currency, this.props.paid, this.props.gameId, this.props.attributionId, this.props.transactionId).then(result => {
            if (result) {
                console.log(result);
                const {dataMessage} = result;
                this.setState({
                    dataMessage
                });
                this.trigger.current.click();
            }
        });
    }

    render() {
        return (
            <Fragment>
                <Form method="post"
                      action={process.env.TwGashOrderUrl}>
                    <input type="hidden" name="data" value={this.state.dataMessage}/>
                    <input type="submit" style={{display: "none"}}
                           ref={this.trigger}/>
                </Form>
                <PAButtonBase price={this.props.product.prices[this.props.currency]}
                              src={this.props.image}
                              disabled={this.props.disabled}
                              hidden={this.props.disabled}
                              onClick={this.handleClick.bind(this)}/>
            </Fragment>
        );
    }
}

CreditPurchaseButtonTwGash.propTypes = {
    image: PropTypes.string.isRequired,
    paid: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired
};

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonTwGash);
