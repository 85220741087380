import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";
import {Image, Loader, Modal} from "semantic-ui-react";
import {QRModal} from "./hupay/lib/qrmodal";

class CreditPurchaseButtonHupay6 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            open: false,
            qrCodeUrl: "",
            transactionId: ""
        };
    }

    onClose() {
        this.setState({
            open: false
        });
    }

    handleClick() {
        if(this.props.disabled) {
            return;
        }
        this.setState({
            loading: true
        });
        new KaisakuUserApi(this.props.session).createTransactionHupay6(this.props.product.productId, this.props.paymentAgency, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
            if(res.status === 200) {
                const {url, url_qrcode, transactionId} = res.data;
                // this.setState({
                //     loading: false,
                //     open: true,
                //     url,
                //     qrCodeUrl: url_qrcode,
                //     transactionId
                // });
                window.location.href = url;
            } else {
                navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
            }
        });
    }

    render() {
        const gameId = this.props.gameId;
        let buttondisabled = this.props.disabled;
        // const gameId1 = "bb20d5cb-2c09-4164-9665-95579d0752ba";
        // if (gameId1 === gameId){;
        //     buttondisabled = true;
        // }
        return (
            <Fragment>
                <Modal basic open={this.state.loading} size='small'>
                    <Modal.Content>
                        <Loader/>
                    </Modal.Content>
                </Modal>
                <Image
                    disabled={buttondisabled}
                    hidden={buttondisabled}
                    src={this.props.image}
                       onClick={this.handleClick.bind(this)}/>
                <QRModal transactionId={this.state.transactionId} qrCodeUrl={this.state.qrCodeUrl}
                         onClose={this.onClose.bind(this)} open={this.state.open}/>
            </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonHupay6);

CreditPurchaseButtonHupay6.propTypes = {
    image: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};