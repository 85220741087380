import React, {Component} from "react";
import {connect} from "react-redux";
import withLocation from "../../wrappers/with-location";
import {formatQuery} from "../../../controllers/utilities/string-utils";
import View from "./payment-provider-selection-view";
import KaisakuApi from "../../../controllers/kaisaku-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import cookie from "react-cookies";
import {rejectIfAnyNull} from "../../../controllers/utilities/env-checks";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";

class PaymentProviderSelection extends Component {
    constructor(props, context) {
        super(props, context);
        const {currency, productId} = formatQuery(props.location.search);
        this.currency = currency;
        this.productId = productId;

        this.state = {
            ready: false
        };
    }

    async componentDidMount() {
        console.log(" this.props.session = " + this.props.session);
        const res = await KaisakuApi.getCreditProduct(this.productId, this.props.session.accessToken);
        // console.log(res.product);
        if(res.product) {
            this.setState({
                ready: false,
                product: res.product,
                follow: res.follow,
                hasEvent: res.events.length > 0
            });
        }
        let hupaystatus = undefined;
        // if ((this.currency === "CNY") || (this.currency === "TWD")){
        hupaystatus = await new KaisakuUserApi(this.props.session).getPaymentStatus(this.currency, this.state.product.prices[this.currency]);
        // }
        this.hupaystatus = hupaystatus;
        this.setState({
            ready: true
        });
    }

    onCancel() {
        const q = cookie.load("_q");
        if (q) {
            let {productId} = formatQuery(q);
            if (productId){
                navigate(`/confirm-purchase`);
            }else{
                navigate(`/credit-packages/?currency=${this.currency}`);    
            }
        } else {
            navigate(`/credit-packages/?currency=${this.currency}`);
        }
    }

    render() {
        let followMode = 1;
        if (!this.state.follow) {
            followMode = 3;
        }
        return (
            <>
            <View ready={this.state.ready} hasEvent={this.state.hasEvent} product={this.state.product} 
            currency={this.currency}
            followMode={followMode}
                  hupaystatus = {this.hupaystatus}
                  onCancel={this.onCancel.bind(this)}
                  mode={this.props.mode}
                  q={this.props.location.search}
            />
            {(this.state.follow) && (this.currency === "RMB" || this.currency === "CNY") && (
                // {(this.state.follow) && (this.currency === "RMB" || this.currency === "CNY" || this.currency === "HKD" || this.currency === "TWD") && (                
                <View ready={this.state.ready} hasEvent={this.state.hasEvent} product={this.state.follow} 
                currency={this.currency}
                followMode={2}
                      hupaystatus = {this.hupaystatus}
                      onCancel={this.onCancel.bind(this)}
                      mode={this.props.mode}
                      q={this.props.location.search}
                />
            )
            }
            </>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(withLocation(PaymentProviderSelection));