import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";
import {Image, Loader, Modal} from "semantic-ui-react";
import PAButtonBase from "./pa-button-base";
import {QRModal} from "./wechatpay/lib/qrmodal";
import {QRModal2} from "./wechatpay/lib/qrmodal2";
import cookie from "react-cookies";

class CreditPurchaseButtonWechatpay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            open: false,
            open2: false,
            qrCodeUrl: "",
            transactionId: "",
        };
    }

    onClose() {
        this.setState({
            loading: false,
            open: false,
            open2: false
        });
    }

    handleClick() {
        if(this.props.disabled) {
            return;
        }
        this.setState({
            loading: true
        });
        new KaisakuUserApi(this.props.session).createTransactionWechatpay(this.props.product.productId, this.props.paymentAgency, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
            if(res.status === 200) {
                const { msg, qrcodedata, transactionId} = res.data;
                if (msg === "success"){
                    cookie.save("_gourlparams", "", {
                        path:"/"
                    });
                    // data:image/png;base64,base64
                    // console.log(qrcodedata);
                    this.setState({
                        loading: false,
                        open: true,
                        qrCodeUrl: 'data:image/png;base64,' + qrcodedata,
                        transactionId
                    });
                }else{
                    navigate(`/credit-purchase-result/?success=${false}&message=${res.data.msg}`)
                }
            } else {
                if ((res.data.message.indexOf("支付忙碌") !== -1) || (res.data.message.indexOf("頻繁操作") !== -1)){
                    this.setState({
                        loading: false,
                        open2: true,
                        message: res.data.message
                    });
                }else{
                    navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
                }
            }
        });
    }

    render() {
        return (
            <Fragment>
                <Modal basic open={this.state.loading} size='small'>
                    <Modal.Content>
                        <Loader/>
                    </Modal.Content>
                </Modal>
                <Image disabled={this.props.disabled}
                       hidden={this.props.disabled}
                       src={this.props.image}
                       onClick={this.handleClick.bind(this)}/>
                <QRModal transactionId={this.state.transactionId} qrCodeUrl={this.state.qrCodeUrl}
                         onClose={this.onClose.bind(this)} open={this.state.open}/>
                <QRModal2 onClose={this.onClose.bind(this)} open={this.state.open2} message={this.state.message}/>                         
            </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonWechatpay);

CreditPurchaseButtonWechatpay.propTypes = {
    image: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};