import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import icon from "../images/jgglogo_202102.svg";
import iconGg from "../images/gg-logo.png";
import { environmentMode } from "../components/lib/ad-manage-api-lib";
import PaymentProviderSelection
    from "../components/forms/payment-provider-selection/payment-provider-selection";
import { formatQuery } from "../controllers/utilities/string-utils";
import { connect } from "react-redux";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import cookie from 'react-cookies';
import "../styles/bg.css";
import PackagePurchaseForm from "../components/forms/package-purchase-form/package-purchase-form";
import NavigationBar from "../components/forms/navigation-bar";
import { navigate } from "gatsby";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        const { currency, productId, _gameId, _accessToken, _transactionId, _attributionId } = formatQuery(props.location.search);
        this.currency = currency;
        this.productId = productId;
        let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
        cookie.save("_productId", this.productId, {
            path: "/",
            expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        });
        if (_transactionId) {
            // console.log("重新登录");
            rejectIfAnyNull(this.props.session, _transactionId);
        }
        this.state = {
            ready: false
        };
        cookie.save("_gourlparams", props.location.search, {
            path: "/",
            expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        });
        // console.log(props.location.search);
        // let _alipaytransactionId = cookie.load("_alipaytransactionId");
        // if (_alipaytransactionId){
        //     cookie.save("_alipaytransactionId", "", {
        //         path:"/",
        //         expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        //     });
        //     // console.log("跳到白页");
        //     const a = this.props.location.href.split("/payment-providers/?");
        //     window.location.href = a[0] + `/credit-purchase-result/?success=${false}&message=${encodeURI('用戶取消')}`;
        // }
    }

    componentDidMount() {
        window.addEventListener('resize', this.getDirection);
        // console.log("456");
        let _action = cookie.load("_action");
        if (_action === undefined) _action = "";
        if (_action === "toalipay") {
            // console.log("清空");
            const _gameId = cookie.load("_gameId");
            const _accessToken = cookie.load("_accessToken");
            const _transactionId = cookie.load("_transactionId");
            const _attributionId = cookie.load("_attributionId");
            const a = this.props.location.href.split("/payment-providers/?");
            // console.log("444");
            if ((_gameId !== undefined) && (_accessToken !== undefined) && (_transactionId !== undefined) && (_attributionId !== undefined)) {
                let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
                cookie.save("_action", this.props.location.href, {
                    path: "/",
                    expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
                });
                let platform = cookie.load("_platform");
                window.location.href = a[0] + `/confirm-purchase/?gameId=${_gameId}&attributionId=${_attributionId}&accessToken=${_accessToken}&transactionId=${_transactionId}&platform=${platform}`;
            } else {
                // console.log("不跳");
            }
            // navigate(`payment-providers/?productId=${this.productId}&currency=${this.currency}&gameId=${_gameId}&accessToken=${_accessToken}&transactionId=${_transactionId}&attributionId=${_attributionId}`);
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getDirection);
    }

    getDirection = () => {
        this.setState({ state: this.state });
    }

    onCancel() {
        const q = cookie.load("_q");
        if (q) {
            let {productId} = formatQuery(q);
            if (productId){
                navigate(`/confirm-purchase`);
            }else{
                navigate(`/credit-packages/?currency=${this.currency}`);    
            }
        } else {
            navigate(`/credit-packages/?currency=${this.currency}`);
        }
    }

    render() {
        const mode = detectOrient();
        if (typeof window !== `undefined`) {
            var docEl = document.documentElement;
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (mode === "PORTRAIT") {
                docEl.style.fontSize = 14 * (clientWidth / 450) + 'px';
            } else {
                docEl.style.fontSize = 14 * (clientWidth / 900) + 'px';
            }
        }
        let panding1 = "1rem";
        let width1 = "97vw";
        if (mode === "PORTRAIT") {
            panding1 = "1rem";
            width1 = "100vw";
        }
        return (
            <div >
                <NavigationBar mode={mode} platform={"h5"} showLogo={1} showTitle={0} onBack={this.onCancel.bind(this)} />

                <div style={{
                    paddingTop: "2vh",
                    width: `${width1}`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: `${panding1}`,
                }}>
                    <Grid textAlign='center' verticalAlign='top'>
                        <Grid.Column style={{
                            margin: '0',
                            maxWidth: 450,
                            paddingTop: "80px",
                            width: '100vw', paddingLeft: 0, paddingRight: 0
                        }}>
                            {
                                (mode === "PORTRAIT") && (
                                    <>
                                        <PaymentProviderSelection mode="PORTRAIT" />
                                    </>
                                )
                            }
                            {
                                (mode === "LANDSCAPE") && (
                                    <>
                                        <PaymentProviderSelection mode="LANDSCAPE" />
                                    </>
                                )
                            }
                        </Grid.Column>
                    </Grid>
                </div>
            </div>
        );
    }
});

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}