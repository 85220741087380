import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";
import {Image, Loader, Modal} from "semantic-ui-react";
import PAButtonBase from "./pa-button-base";
import {getUA} from "react-device-detect";
import cookie from 'react-cookies';
import asiaunionpay4 from "./asia/images/asiaunionpay4_96.png";
import asiaunionpay4_95 from "./asia/images/asiaunionpay4_94.png";
import { QRModal } from "./geounionpay/lib/qrmodal";
import { QRModal2 } from "./geounionpay/lib/qrmodal2";


class CreditPurchaseButtonGeounionpay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            open: false,
            open2: false,
            sec: 60,
            qrCodeUrl: "",
            transactionId: "",
        };
    }

    onClose() {
        this.setState({
            open: false,
            open2: false
        });
    }

    handleClick() {
        if(this.props.disabled) {
            return;
        }
        this.setState({
            loading: true
        });
        new KaisakuUserApi(this.props.session).createTransactionGeounionpay(this.props.product.productId, this.props.paymentAgency, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
            if (res.status === 200) {
                console.log(res.data);
                const { redirectUrl, transactionId } = res.data;
                if (redirectUrl) {
                    console.log(redirectUrl);
                    this.setState({
                        loading: false,
                        open: true,
                        resultUrl: redirectUrl,
                        qrCodeUrl: redirectUrl,
                        transactionId
                    });
                    // let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
                    // cookie.save("_action", "toalipay", {
                    //     path:"/",
                    //     expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
                    // });
                    // cookie.save("_alipaytransactionId", transactionId, {
                    //     path:"/",
                    //     expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
                    // });
                    // console.log("789");
                    // console.log(cookie.load("_action"));
                    // window.location.href = data;
                    // this.setState({
                    //     loading: false,
                    //     open: true
                    // });
                    // document.open();
                    // document.write(result);
                    // document.close();
                } else {
                    navigate(`/credit-purchase-result/?success=${false}&message=${encodeURI('請稍候重試交易~')}`)
                }

            } else {
                try {
                    if (res.data.message.indexOf("支付冷卻中") !== -1) {
                        let sec = 60;
                        const a = res.data.message.split("|");
                        if (a.length > 1) {
                            sec = parseInt(a[1]);
                        }
                        this.setState({
                            loading: false,
                            open2: true,
                            sec: sec
                        });
                    } else {
                        navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
                    }
                } catch (err) {
                    navigate(`/credit-purchase-result/?success=${false}&message=${encodeURI('請稍候重試~')}`)
                }
            }
        });
    }

    render() {
        // const gameId1 = "aac53ce9-f03c-4e71-953b-df9ec022a837";
        // const gameId2 = "aac53ce9-f03c-4e71-953b-df9ec022a837";
        // const gameId3 = "b15402f9-6bca-4c17-abed-5ba00f7dd0c7";
        // const gameId4 = "de7679d9-c5b5-47ae-84f5-ad96be7d612e";
        // const gameId5 = "f552d32d-f73a-46c6-ba20-42098ed27c1c";
        // const gameId6 = "87cd854c-f5f4-4018-a57d-6d49007931db";

        const gameId = this.props.gameId;
        let buttondisabled = this.props.disabled;
        // if (((gameId1 === gameId) || (gameId2 === gameId) || (gameId3 === gameId) || (gameId4 === gameId)
        //     || (gameId5 === gameId) || (gameId6 === gameId))){;
        //     buttondisabled = true;
        // }
        let image = this.props.image;
        const list = [
            "6c7b59f2-5922-411b-0729-5332c0ddcb02",
            "6c7b59f2-5922-411b-0729-5332c0ddea02",
            "d1747cc0-b347-46ba-0729-680011223302",
            "ae5eb5b7-fcfc-4628-0729-749676c22702",
            "ae5eb5b7-fcfc-4628-0729-749676c25b02",
            "74f12a51-18c4-488a-0729-ca0e73348d02",
            "64b51ae5-24c0-42db-97a4-eaf444160002",
            "ed744519-0c1b-43a0-8704-2b3b075f0002",
            "a043fc6e-e0de-4f96-8d5c-2fdbc6090002",

            "47bcf735-6d24-4100-0729-c0e50b74b502",
            "5264a4c9-5a8f-47e3-0729-c4d9c0ddfe02",
            "f01f1a69-d7d4-4255-0729-7f06a0904a02",
            "6c7b59f2-5922-411b-0729-5332c0ddcb02",
            "ae5eb5b7-fcfc-4628-0729-749676c22702",
            "2ee475a6-e327-402a-0729-b3c9d182de02",
            "8026d567-d19f-4507-bedd-e6c6e3190002",
            "28dce5a7-2029-446a-90dc-c31ad1410002",
            "c83083e6-edf6-4bd6-bd7e-027a51d00002",

            "d2f871fa-485e-4953-2022-c434df7b8f6e",
            "149a7e09-7c8e-4601-2022-6a80f453ada0",
            "40ddc41c-48ab-42bd-2022-f3e1bd1ff6f5",
            "7189ffe3-b4dc-4d7f-2022-63230c784d37",
            "81d0d75c-d00c-4aa1-2022-39bf7b0d2608",
            "46b7044e-6d24-4de3-2022-a8aad45d0aa0"

            ];
        if (
            list.includes(this.props.product.productId)
           ){
            image = asiaunionpay4_95; 
        }

        const list97 = [
            "6c7b59f2-2022-411b-0331-5332c0ddcb02",
            "6c7b59f2-2022-411b-0331-5332c0ddea02",
            "d1747cc0-2022-46ba-0331-680011223302",
            "ae5eb5b7-2022-4628-0331-749676c22702",
            "ae5eb5b7-2022-4628-0331-749676c25b02",
            "74f12a51-2022-488a-0331-ca0e73348d02",
            "64b51ae5-2022-42db-0331-eaf444160002",
            "ed744519-2022-43a0-0331-2b3b075f0002",
            "a043fc6e-2022-4f96-0331-2fdbc6090002",

            "47bcf735-2022-4100-0331-c0e50b74b502",
            "5264a4c9-2022-47e3-0331-c4d9c0ddfe02",
            "f01f1a69-2022-4255-0331-7f06a0904a02",
            "6c7b59f2-2022-411b-0331-5332c0ddcb02",
            "ae5eb5b7-2022-4628-0331-749676c22702",
            "2ee475a6-2022-402a-0331-b3c9d182de02",
            "8026d567-2022-4507-0331-e6c6e3190002",
            "28dce5a7-2022-446a-0331-c31ad1410002",
            "c83083e6-2022-4bd6-0331-027a51d00002",

        ];
        if (
            list97.includes(this.props.product.productId)
        ) {
            image = asiaunionpay4;
        }
        return (
            <Fragment>
                <Modal basic open={this.state.loading} size='small'>
                    <Modal.Content>
                        <Loader />
                    </Modal.Content>
                </Modal>
                <Image disabled={this.props.disabled}
                    hidden={this.props.disabled}
                    src={image}
                    onClick={this.handleClick.bind(this)} />
                <QRModal transactionId={this.state.transactionId} resultUrl={this.state.resultUrl} qrCodeUrl={this.state.qrCodeUrl}
                    onClose={this.onClose.bind(this)} open={this.state.open} />
                <QRModal2 onClose={this.onClose.bind(this)} open={this.state.open2} sec={this.state.sec} />
            </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonGeounionpay);

CreditPurchaseButtonGeounionpay.propTypes = {
    image: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};