import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";
import {Image, Loader, Modal} from "semantic-ui-react";
import {QRModal} from "./ktpay/lib/qrmodal";
import {QRModal2} from "./ktpay/lib/qrmodal2";

class CreditPurchaseButtonKtpay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            open: false,
            open2: false,
            sec:60,
            qrCodeUrl: "",
            transactionId: ""
        };
    }

    onClose() {
        this.setState({
            open: false,
            open2: false
        });
    }

     handleClick() {
        if(this.props.disabled) {
            return;
        }
        this.setState({
            loading: true
        });
        
        console.log(this.props);
        new KaisakuUserApi(this.props.session).createTransactionKtpay(this.props.product.productId, this.props.paymentAgency, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
            if(res.status === 200) {
                const {resultUrl, transactionId} = res.data;
                    try {
                        this.setState({
                            loading: false,
                            open: true,
                            resultUrl: resultUrl,
                            transactionId
                        });

                    }catch(err) {
                        console.log(err);
                        navigate(`credit-purchase-result/?success=${false}&message=${encodeURI('請稍候重試交易~')}`);
                    }

            } else {
                if (res.data.message.indexOf("支付冷卻中") !== -1){
                    let sec = 60;
                    const a = res.data.message.split("|");
                    if (a.length > 1){
                        sec = parseInt(a[1]);
                    }
                    this.setState({
                        loading: false,
                        open2: true,
                        sec:sec
                    });
                }else{
                    navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
                }                
            }
        });
    }

    render() {
        return (
            <Fragment>
                <Modal basic open={this.state.loading} size='small'>
                    <Modal.Content>
                        <Loader/>
                    </Modal.Content>
                </Modal>
                <Image disabled={this.props.disabled}
                       hidden={this.props.disabled}
                       src={this.props.image}
                       onClick={this.handleClick.bind(this)}/>
                <QRModal transactionId={this.state.transactionId} resultUrl={this.state.resultUrl}
                         onClose={this.onClose.bind(this)} open={this.state.open}/>
                <QRModal2 onClose={this.onClose.bind(this)} open={this.state.open2} sec={this.state.sec}/>                         
            </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonKtpay);

CreditPurchaseButtonKtpay.propTypes = {
    image: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};