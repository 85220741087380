import React, { Component } from "react";
import i18next from "i18next";
import { Button, Card, Container, Dimmer, Loader, Image } from "semantic-ui-react";
import bar from "../../../images/bar2.jpg";
import eventBar2 from "../../../images/payment/event-bar.png";
import eventBar2Vip from "../../../images/payment/event-barVip.png";
import eventBar2Vip1 from "../../../images/payment/event-barVip1.png";
import eventBar2Vip2 from "../../../images/payment/event-barVip2.png";
import eventBar2Vip3 from "../../../images/payment/event-barVip3.png";

import timeSpace from "../../../images/TimeSpace.jpg";
import payment from "../../../images/payment.jpg";

import payment_step1 from "../../../images/step1.jpg";
import payment_step2 from "../../../images/step2.jpg";
import payment_step3 from "../../../images/step3.jpg";
import payment_step4 from "../../../images/step4.jpg";
import payment_step5 from "../../../images/step5.jpg";
import payment_step6 from "../../../images/step6.jpg";
import payment_step7 from "../../../images/step7.jpg";
import payment_step8 from "../../../images/step8.jpg";
import payment_step9 from "../../../images/step9.jpg";
import payment_step10 from "../../../images/step10.jpg";
import payment_step11 from "../../../images/step11.jpg";
import payment_step12 from "../../../images/step12.jpg";
import payment_step13 from "../../../images/step13.jpg";
import payment_step14 from "../../../images/step14.jpg";
import payment_step15 from "../../../images/step15.jpg";
import payment_step16 from "../../../images/step16.jpg";
import payment_step17 from "../../../images/step17.jpg";
import payment_step18 from "../../../images/step18.jpg";
import payment_step19 from "../../../images/step19.jpg";
import payment_step20 from "../../../images/step20.jpg";
import payment_step21 from "../../../images/step21.jpg";
import payment_step22 from "../../../images/step22.jpg";
import payment_step23 from "../../../images/step23.jpg";
import payment_step24 from "../../../images/step24.jpg";
import payment_step25 from "../../../images/step25.jpg";
import payment_step26 from "../../../images/step26.jpg";
import payment_step27 from "../../../images/step27.jpg";
import payment_step28 from "../../../images/step28.jpg";
import payment_step29 from "../../../images/step29.jpg";
import payment_step30 from "../../../images/step30.jpg";

import iconline from "../../../images/main/line.png";

import payment_noodle from "../../../images/Artboard-noodle.jpg";
import payment_payment from "../../../images/Artboard-payment.jpg";
import payment_noodle_j4 from "../../../images/Artboard-noodle-j4.jpg";
import payment_payment_j4 from "../../../images/Artboard-payment-j4.jpg";
import payment_noodle_unionpay from "../../../images/Artboard-noodle-unionpay.jpg";
import payment_payment_unionpay from "../../../images/Artboard-payment-unionpay.jpg";

import payment_noodle_nw_1 from "../../../images/payment_noodle_nw_1.jpg";
import payment_noodle_nw_2 from "../../../images/payment_noodle_nw_2.jpg";
import payment_noodle_nw_3 from "../../../images/payment_noodle_nw_3.jpg";
import payment_noodle_nw_4 from "../../../images/payment_noodle_nw_4.jpg";
import payment_payment_nw_1 from "../../../images/payment_payment_nw_1.jpg";
import payment_payment_nw_2 from "../../../images/payment_payment_nw_2.jpg";
import payment_payment_nw_3 from "../../../images/payment_payment_nw_3.jpg";
import payment_payment_nw_4 from "../../../images/payment_payment_nw_4.jpg";

import payment_noodle_nw_5 from "../../../images/payment_noodle_nw_5.jpg";
import payment_payment_nw_5 from "../../../images/payment_payment_nw_5.jpg";
import payment_noodle_nw_6 from "../../../images/payment_noodle_nw_6.jpg";
import payment_payment_nw_6 from "../../../images/payment_payment_nw_6.jpg";
import payment_noodle_nw_7 from "../../../images/payment_noodle_nw_7.jpg";
import payment_payment_nw_7 from "../../../images/payment_payment_nw_7.jpg";

import payment_noodle_bc_1 from "../../../images/payment_noodle_bc_1.jpg";
import payment_noodle_bc_2 from "../../../images/payment_noodle_bc_2.jpg";
import payment_noodle_bc_3 from "../../../images/payment_noodle_bc_3.jpg";
import payment_noodle_bc_4 from "../../../images/payment_noodle_bc_4.jpg";
import payment_noodle_bc_5 from "../../../images/payment_noodle_bc_5.jpg";
import payment_noodle_bc_6 from "../../../images/payment_noodle_bc_6.jpg";
import payment_noodle_bc_7 from "../../../images/payment_noodle_bc_7.jpg";
import payment_payment_bc from "../../../images/payment_payment_bc.jpg";

import payment_noodle_fr_1 from "../../../images/payment_noodle_fr_1.jpg";
import payment_noodle_fr_2 from "../../../images/payment_noodle_fr_2.jpg";
import payment_noodle_fr_3 from "../../../images/payment_noodle_fr_3.jpg";
import payment_noodle_fr_4 from "../../../images/payment_noodle_fr_4.jpg";
import payment_payment_fr from "../../../images/payment_payment_fr.jpg";

import payment_noodle_nu_1 from "../../../images/payment_noodle_nu_1.jpg";
import payment_noodle_nu_2 from "../../../images/payment_noodle_nu_2.jpg";
import payment_noodle_nu_3 from "../../../images/payment_noodle_nu_3.jpg";
import payment_noodle_nu_4 from "../../../images/payment_noodle_nu_4.jpg";
import payment_noodle_nu_5 from "../../../images/payment_noodle_nu_5.jpg";
import payment_noodle_nu_6 from "../../../images/payment_noodle_nu_6.jpg";
import payment_noodle_nu_7 from "../../../images/payment_noodle_nu_7.jpg";
import payment_noodle_nu_8 from "../../../images/payment_noodle_nu_8.jpg";
import payment_noodle_nu_9 from "../../../images/payment_noodle_nu_9.jpg";
import payment_noodle_nu_10 from "../../../images/payment_noodle_nu_10.jpg";
import payment_noodle_nu_11 from "../../../images/payment_noodle_nu_11.jpg";
import payment_noodle_nu_12 from "../../../images/payment_noodle_nu_12.jpg";
import payment_noodle_nu_13 from "../../../images/payment_noodle_nu_13.jpg";
import payment_noodle_nu_14 from "../../../images/payment_noodle_nu_14.jpg";
import payment_payment_nu from "../../../images/payment_payment_nu.jpg";
import payment_payment_pp from "../../../images/payment_payment_pp.jpg";

import payment_noodle_vip_1 from "../../../images/Artboard-noodle-vip-1.jpg";
import payment_noodle_vip_2 from "../../../images/Artboard-noodle-vip-2.jpg";
import payment_noodle_vip_3 from "../../../images/Artboard-noodle-vip-3.jpg";
import payment_noodle_vip_4 from "../../../images/Artboard-noodle-vip-4.jpg";
import payment_noodle_vip_5 from "../../../images/Artboard-noodle-vip-5.jpg";
import payment_noodle_vip_6 from "../../../images/Artboard-noodle-vip-6.jpg";
import payment_noodle_vip_7 from "../../../images/Artboard-noodle-vip-7.jpg";
import payment_noodle_vip_8 from "../../../images/Artboard-noodle-vip-8.jpg";
import payment_noodle_vip_9 from "../../../images/Artboard-noodle-vip-9.jpg";
import payment_noodle_vip_10 from "../../../images/Artboard-noodle-vip-10.jpg";
import payment_noodle_vip_11 from "../../../images/Artboard-noodle-vip-11.jpg";
import payment_noodle_vip_12 from "../../../images/Artboard-noodle-vip-12.jpg";
import payment_noodle_vip_13 from "../../../images/Artboard-noodle-vip-13.jpg";
import payment_noodle_vip_14 from "../../../images/Artboard-noodle-vip-14.jpg";
import payment_noodle_vip_15 from "../../../images/Artboard-noodle-vip-15.jpg";
import payment_noodle_vip_16 from "../../../images/Artboard-noodle-vip-16.jpg";
import payment_noodle_vip_17 from "../../../images/Artboard-noodle-vip-17.jpg";
import payment_payment_vip from "../../../images/Artboard-payment-vip.jpg";

import payment_payment_ml from "../../../images/noodles/4/Artboard-payment-ml.jpg";
import payment_noodle_ml_1 from "../../../images/noodles/4/Artboard-noodle-ml-1.jpg";
import payment_noodle_ml_2 from "../../../images/noodles/4/Artboard-noodle-ml-2.jpg";
import payment_noodle_ml_3 from "../../../images/noodles/4/Artboard-noodle-ml-3.jpg";
import payment_noodle_ml_4 from "../../../images/noodles/4/Artboard-noodle-ml-4.jpg";
import payment_noodle_ml_5 from "../../../images/noodles/4/Artboard-noodle-ml-5.jpg";


import payment_payment_ll_1 from "../../../images/noodles/9/Artboard-payment-ll-1.jpg";
import payment_payment_ll_2 from "../../../images/noodles/9/Artboard-payment-ll-2.jpg";
import payment_payment_ll_3 from "../../../images/noodles/9/Artboard-payment-ll-3.jpg";
import payment_payment_ll_4 from "../../../images/noodles/9/Artboard-payment-ll-4.jpg";
import payment_payment_ll_5 from "../../../images/noodles/9/Artboard-payment-ll-5.jpg";
import payment_payment_ll_6 from "../../../images/noodles/9/Artboard-payment-ll-6.jpg";
import payment_payment_ll_7 from "../../../images/noodles/9/Artboard-payment-ll-7.jpg";
import payment_payment_ll_8 from "../../../images/noodles/9/Artboard-payment-ll-8.jpg";

import payment_noodle_ll_1 from "../../../images/noodles/9/Artboard-noodle-ll-1.jpg";
import payment_noodle_ll_2 from "../../../images/noodles/9/Artboard-noodle-ll-2.jpg";
import payment_noodle_ll_3 from "../../../images/noodles/9/Artboard-noodle-ll-3.jpg";
import payment_noodle_ll_4 from "../../../images/noodles/9/Artboard-noodle-ll-4.jpg";
import payment_noodle_ll_5 from "../../../images/noodles/9/Artboard-noodle-ll-5.jpg";
import payment_noodle_ll_6 from "../../../images/noodles/9/Artboard-noodle-ll-6.jpg";
import payment_noodle_ll_7 from "../../../images/noodles/9/Artboard-noodle-ll-7.jpg";
import payment_noodle_ll_8 from "../../../images/noodles/9/Artboard-noodle-ll-8.jpg";

import payment_payment_lc_1 from "../../../images/noodles/9/Artboard-payment-lc-1.jpg";
import payment_payment_lc_2 from "../../../images/noodles/9/Artboard-payment-lc-2.jpg";
import payment_payment_lc_3 from "../../../images/noodles/9/Artboard-payment-lc-3.jpg";
import payment_payment_lc_4 from "../../../images/noodles/9/Artboard-payment-lc-4.jpg";
import payment_payment_lc_5 from "../../../images/noodles/9/Artboard-payment-lc-5.jpg";
import payment_payment_lc_6 from "../../../images/noodles/9/Artboard-payment-lc-6.jpg";
import payment_payment_lc_7 from "../../../images/noodles/9/Artboard-payment-lc-7.jpg";
import payment_payment_lc_8 from "../../../images/noodles/9/Artboard-payment-lc-8.jpg";
import payment_payment_lc_9 from "../../../images/noodles/9/Artboard-payment-lc-9.jpg";
import payment_payment_lc_10 from "../../../images/noodles/9/Artboard-payment-lc-10.jpg";

import payment_noodle_lc_1 from "../../../images/noodles/9/Artboard-noodle-lc-1.jpg";
import payment_noodle_lc_2 from "../../../images/noodles/9/Artboard-noodle-lc-2.jpg";
import payment_noodle_lc_3 from "../../../images/noodles/9/Artboard-noodle-lc-3.jpg";
import payment_noodle_lc_4 from "../../../images/noodles/9/Artboard-noodle-lc-4.jpg";
import payment_noodle_lc_5 from "../../../images/noodles/9/Artboard-noodle-lc-5.jpg";
import payment_noodle_lc_6 from "../../../images/noodles/9/Artboard-noodle-lc-6.jpg";
import payment_noodle_lc_7 from "../../../images/noodles/9/Artboard-noodle-lc-7.jpg";
import payment_noodle_lc_8 from "../../../images/noodles/9/Artboard-noodle-lc-8.jpg";
import payment_noodle_lc_9 from "../../../images/noodles/9/Artboard-noodle-lc-9.jpg";
import payment_noodle_lc_10 from "../../../images/noodles/9/Artboard-noodle-lc-10.jpg";

import payment_payment_ggs_1 from "../../../images/noodles/9/Artboard-payment-ggs-1.jpg";
import payment_payment_ggs_2 from "../../../images/noodles/9/Artboard-payment-ggs-2.jpg";
import payment_payment_ggs_3 from "../../../images/noodles/9/Artboard-payment-ggs-3.jpg";
import payment_payment_ggs_4 from "../../../images/noodles/9/Artboard-payment-ggs-4.jpg";
import payment_payment_ggs_5 from "../../../images/noodles/9/Artboard-payment-ggs-5.jpg";
import payment_payment_ggs_6 from "../../../images/noodles/9/Artboard-payment-ggs-6.jpg";
import payment_payment_ggs_7 from "../../../images/noodles/9/Artboard-payment-ggs-7.jpg";
import payment_payment_ggs_8 from "../../../images/noodles/9/Artboard-payment-ggs-8.jpg";

import payment_noodle_ggs_1 from "../../../images/noodles/9/Artboard-noodle-ggs-1.jpg";
import payment_noodle_ggs_2 from "../../../images/noodles/9/Artboard-noodle-ggs-2.jpg";
import payment_noodle_ggs_3 from "../../../images/noodles/9/Artboard-noodle-ggs-3.jpg";
import payment_noodle_ggs_4 from "../../../images/noodles/9/Artboard-noodle-ggs-4.jpg";
import payment_noodle_ggs_5 from "../../../images/noodles/9/Artboard-noodle-ggs-5.jpg";
import payment_noodle_ggs_6 from "../../../images/noodles/9/Artboard-noodle-ggs-6.jpg";
import payment_noodle_ggs_7 from "../../../images/noodles/9/Artboard-noodle-ggs-7.jpg";
import payment_noodle_ggs_8 from "../../../images/noodles/9/Artboard-noodle-ggs-8.jpg";

import { keyToDisplay } from "../../lib/currency-button-base";

export default (props) => {
    let { onCancel, ready, products, missingGold, currency, onSelect, hasEvent, mode, idxxh } = props;

    products = products.sort(function (a, b) {
        if (!a.isVip) a.isVip = 0;
        if (!b.isVip) b.isVip = 0;
        return b.isVip - a.isVip
    });
    products = products.sort(function (a, b) {
        if (!a.isVIPsub) a.isVIPsub = 0;
        if (!b.isVIPsub) b.isVIPsub = 0;
        return b.isVIPsub - a.isVIPsub
    });
    products = products.sort(function (a, b) {
        if (!a.onlyone) a.onlyone = 0;
        if (!b.onlyone) b.onlyone = 0;
        return b.onlyone - a.onlyone
    });
    // 測試才排下序看看
    // products = products.sort(function(a,b){
    //     if (!a.xh) a.xh = 1000;
    //     if (!b.xh) b.xh = 1000;
    //     return a.xh - b.xh
    // });
    products = products.sort(function (a, b) {
        if (!a.isNoodle1) a.isNoodle1 = 0;
        if (!b.isNoodle1) b.isNoodle1 = 0;
        return b.isNoodle1 - a.isNoodle1
    });

    idxxh = 0;
    let display = "none";
    let hintMessage = "";
    if (currency === "USD") {
        display = "flex";
        hintMessage = "注意：手續費 0.5 美元";
    }
    let float1 = "";
    if (mode === "LANDSCAPE") {
        float1 = "left";
    }

    let _font = [];
    _font["CNY1"] = "417";
    _font["CNY2"] = "1047";
    _font["CNY3"] = "1666";
    _font["CNY4"] = "2821";
    _font["CNY5"] = "4080";

    _font["HKD1"] = "492";
    _font["HKD2"] = "1235";
    _font["HKD3"] = "1965";
    _font["HKD4"] = "3327";
    _font["HKD5"] = "4812";

    _font["TWD1"] = "1871";
    _font["TWD2"] = "4698";
    _font["TWD3"] = "7475";
    _font["TWD4"] = "12654";
    _font["TWD5"] = "18303";

    _font["USD1"] = "62.59";
    _font["USD2"] = "157.18";
    _font["USD3"] = "250.11";
    _font["USD4"] = "423.42";
    _font["USD5"] = "612.48";

    _font["MYR1"] = "294";
    _font["MYR2"] = "737";
    _font["MYR3"] = "1173";
    _font["MYR4"] = "1985";
    _font["MYR5"] = "2871";
    return (
        <React.Fragment>
            <Dimmer page active={!ready}>
                <Loader />
            </Dimmer>
            <div
                style={{
                    width: '100%',
                    height: "10%",
                    display: `${(ready) ? "" : "none"}`
                }}
                ref={(node) => {
                    if (node) {
                        node.style.setProperty('margin', `auto`, 'important');
                    }
                }}
            >
                {/* <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#8c0702',
                    padding: '7px',
                    borderRadius: '0.25rem'
                }}>
                    <span style={{
                        color: 'white',
                        fontWeight: 'bolder',
                        fontSize: '20px'
                    }}>請選擇你的金額</span>
                    <Button style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: '#570703', color: '#fff' }} onClick={onCancel}>取消</Button>
                </div> */}
                <div style={{ width: '100%', color: '#fff', paddingLeft: '0px', display: `${display}` }}>{hintMessage}</div>
                {products.filter(p => {
                    if (missingGold) {
                        return p.gold >= missingGold;
                    }
                    return true;
                }).map((product, idx) => {
                    let xh = "";
                    let bonusGoldMessage = "";
                    let bonusGoldMessagehint = "";
                    if (hasEvent) {
                        if (product.bonusGold.holiday > 0) {
                            if ((product.gold + product.bonusGold.holiday) !== product.goldbase) {
                                bonusGoldMessage += ` (${product.goldbase})`;
                            }
                        }
                        if (product.bonusGold.holidayhint) {
                            bonusGoldMessagehint = product.bonusGold.holidayhint;
                        }
                    }
                    let _background1 = [];
                    let background1 = "";

                    _background1["payment_step1"] = payment_step1;
                    _background1["payment_step2"] = payment_step2;
                    _background1["payment_step3"] = payment_step3;
                    _background1["payment_step4"] = payment_step4;
                    _background1["payment_step5"] = payment_step5;
                    _background1["payment_step6"] = payment_step6;
                    _background1["payment_step7"] = payment_step7;
                    _background1["payment_step8"] = payment_step8;
                    _background1["payment_step9"] = payment_step9;
                    _background1["payment_step10"] = payment_step10;
                    _background1["payment_step11"] = payment_step11;
                    _background1["payment_step12"] = payment_step12;
                    _background1["payment_step13"] = payment_step13;
                    _background1["payment_step14"] = payment_step14;
                    _background1["payment_step15"] = payment_step15;
                    _background1["payment_step16"] = payment_step16;
                    _background1["payment_step17"] = payment_step17;
                    _background1["payment_step18"] = payment_step18;
                    _background1["payment_step19"] = payment_step19;
                    _background1["payment_step20"] = payment_step20;
                    _background1["payment_step21"] = payment_step21;
                    _background1["payment_step22"] = payment_step22;
                    _background1["payment_step23"] = payment_step23;
                    _background1["payment_step24"] = payment_step24;
                    _background1["payment_step25"] = payment_step25;
                    _background1["payment_step26"] = payment_step26;
                    _background1["payment_step27"] = payment_step27;
                    _background1["payment_step28"] = payment_step28;
                    _background1["payment_step29"] = payment_step29;
                    _background1["payment_step30"] = payment_step30;

                    if ((mode === "LANDSCAPE") && ((product.isNoodle1) || (product.isNoodle2))) {
                        idxxh--;
                    }
                    if (product.xh) {
                        idxxh++;
                        xh = product.xh;
                        background1 = _background1[product.background + "_step" + xh];
                        // xh = parseInt(xh) % 5;
                        // if (xh === 0) xh = 5;

                    }
                    let background = hasEvent ? eventBar2 : bar;
                    if (product.isVip){
                        background = eventBar2Vip;
                    }
                    if (product.isVIPsub) {
                        if (product.viplv === 1) {
                            background = eventBar2Vip1;
                        }else
                        if (product.viplv === 2) {
                            background = eventBar2Vip2;
                        }else
                        if (product.viplv === 3) {
                            background = eventBar2Vip3;
                        }
                    }

                    if ((product.onlyone) && (product.background)) {
                        if (product.background === "payment") {
                            background = payment;
                        } else {
                            background = product.background;
                        }
                    } else if (product.isNoodle1 || product.isNoodle2) {
                        background1 = payment_noodle;
                        background = payment_payment;
                        if (product.noodle === "sdk-ad-noodle-unionpay") {
                            background1 = payment_noodle_unionpay;
                            background = payment_payment_unionpay;
                        } else if (product.noodle === "sdk-ad-noodle-fr") {
                            background = payment_payment_fr;
                            switch (parseInt(product.xh1)) {
                                case 1:
                                    background1 = payment_noodle_fr_1;
                                    break;
                                case 2:
                                    background1 = payment_noodle_fr_2;
                                    break;
                                case 3:
                                    background1 = payment_noodle_fr_3;
                                    break;
                                case 4:
                                    background1 = payment_noodle_fr_4;
                                    break;
                            }
                        } else if (product.noodle === "sdk-ad-noodle-bc") {
                            background = payment_payment_bc;
                            switch (parseInt(product.xh1)) {
                                case 1:
                                    background1 = payment_noodle_bc_1;
                                    break;
                                case 2:
                                    background1 = payment_noodle_bc_2;
                                    break;
                                case 3:
                                    background1 = payment_noodle_bc_3;
                                    break;
                                case 4:
                                    background1 = payment_noodle_bc_4;
                                    break;
                                case 5:
                                    background1 = payment_noodle_bc_5;
                                    break;
                                case 6:
                                    background1 = payment_noodle_bc_6;
                                    break;
                                case 7:
                                    background1 = payment_noodle_bc_7;
                                    break;
                            }
                        }
                        else if (product.noodle === "sdk-ad-noodle-nw") {
                            switch (parseInt(product.xh1)) {
                                case 1:
                                    background1 = payment_noodle_nw_1;
                                    background = payment_payment_nw_1;
                                    break;
                                case 2:
                                    background1 = payment_noodle_nw_2;
                                    background = payment_payment_nw_2;
                                    break;
                                case 3:
                                    background1 = payment_noodle_nw_3;
                                    background = payment_payment_nw_3;
                                    break;
                                case 4:
                                    background1 = payment_noodle_nw_4;
                                    background = payment_payment_nw_4;
                                    break;
                                case 5:
                                    background1 = payment_noodle_nw_5;
                                    background = payment_payment_nw_5;
                                    break;
                                case 6:
                                    background1 = payment_noodle_nw_6;
                                    background = payment_payment_nw_6;
                                    break;
                                case 7:
                                    background1 = payment_noodle_nw_7;
                                    background = payment_payment_nw_7;
                                    break;
                            }
                        } else if (product.noodle === "sdk-ad-noodle-nu") {
                            background = payment_payment_nu;
                            switch (parseInt(product.xh1)) {
                                case 1:
                                    background1 = payment_noodle_nu_1;
                                    break;
                                case 2:
                                    background1 = payment_noodle_nu_2;
                                    break;
                                case 3:
                                    background1 = payment_noodle_nu_3;
                                    break;
                                case 4:
                                    background1 = payment_noodle_nu_4;
                                    break;
                                case 5:
                                    background1 = payment_noodle_nu_5;
                                    break;
                                case 6:
                                    background1 = payment_noodle_nu_6;
                                    break;
                                case 7:
                                    background1 = payment_noodle_nu_7;
                                    break;
                                case 8:
                                    background1 = payment_noodle_nu_8;
                                    break;
                                case 9:
                                    background1 = payment_noodle_nu_9;
                                    break;
                                case 10:
                                    background1 = payment_noodle_nu_10;
                                    break;
                                case 11:
                                    background1 = payment_noodle_nu_11;
                                    break;
                                case 12:
                                    background1 = payment_noodle_nu_12;
                                    background = payment_payment_pp;
                                    break;
                                case 13:
                                    background1 = payment_noodle_nu_13;
                                    background = payment_payment_pp;
                                    break;
                                case 14:
                                    background1 = payment_noodle_nu_14;
                                    background = payment_payment_pp;
                                    break;
                            }

                        } else if (product.noodle === "sdk-ad-noodle-ml") {
                            background = payment_payment_ml;
                            switch (parseInt(product.xh1)) {
                                case 1:
                                    background1 = payment_noodle_ml_1;
                                    break;
                                case 2:
                                    background1 = payment_noodle_ml_2;
                                    break;
                                case 3:
                                    background1 = payment_noodle_ml_3;
                                    break;
                                case 4:
                                    background1 = payment_noodle_ml_4;
                                    break;
                                case 5:
                                    background1 = payment_noodle_ml_5;
                                    break;
                            }

                        } else if (product.noodle === "sdk-ad-noodle-j4") {
                            background1 = payment_noodle_j4;
                            background = payment_payment_j4;
                        } else if (product.noodle === "sdk-ad-noodle-vip") {
                            background1 = payment_noodle_vip_1;
                            switch (parseInt(product.group)) {
                                case 1:
                                    background1 = payment_noodle_vip_1;
                                    break;
                                case 2:
                                    background1 = payment_noodle_vip_2;
                                    break;
                                case 3:
                                    background1 = payment_noodle_vip_3;
                                    break;
                                case 4:
                                    background1 = payment_noodle_vip_4;
                                    break;
                                case 5:
                                    background1 = payment_noodle_vip_5;
                                    break;
                                case 6:
                                    background1 = payment_noodle_vip_6;
                                    break;
                                case 7:
                                    background1 = payment_noodle_vip_7;
                                    break;
                                case 8:
                                    background1 = payment_noodle_vip_8;
                                    break;
                                case 9:
                                    background1 = payment_noodle_vip_9;
                                    break;
                                case 10:
                                    background1 = payment_noodle_vip_10;
                                    break;
                                case 11:
                                    background1 = payment_noodle_vip_11;
                                    break;
                                case 12:
                                    background1 = payment_noodle_vip_12;
                                    break;
                                case 13:
                                    background1 = payment_noodle_vip_13;
                                    break;
                                case 14:
                                    background1 = payment_noodle_vip_14;
                                    break;
                                case 15:
                                    background1 = payment_noodle_vip_15;
                                    break;
                                case 16:
                                    background1 = payment_noodle_vip_16;
                                    break;
                                case 17:
                                    background1 = payment_noodle_vip_17;
                                    break;
                            }
                            background = payment_payment_vip;
                        } else if (product.noodle === "sdk-ad-noodle-ll") {
                            background1 = payment_noodle_ll_1;
                            switch (parseInt(product.xh1)) {
                                case 1:
                                    background1 = payment_noodle_ll_1;
                                    background = payment_payment_ll_1;
                                    break;
                                case 2:
                                    background1 = payment_noodle_ll_2;
                                    background = payment_payment_ll_2;
                                    break;
                                case 3:
                                    background1 = payment_noodle_ll_3;
                                    background = payment_payment_ll_3;
                                    break;
                                case 4:
                                    background1 = payment_noodle_ll_4;
                                    background = payment_payment_ll_4;
                                    break;
                                case 5:
                                    background1 = payment_noodle_ll_5;
                                    background = payment_payment_ll_5;
                                    break;
                                case 6:
                                    background1 = payment_noodle_ll_6;
                                    background = payment_payment_ll_6;
                                    break;
                                case 7:
                                    background1 = payment_noodle_ll_7;
                                    background = payment_payment_ll_7;
                                    break;
                                case 8:
                                    background1 = payment_noodle_ll_8;
                                    background = payment_payment_ll_8;
                                    break;
                            }
                        } else if (product.noodle === "sdk-ad-noodle-lc") {
                            background1 = payment_noodle_lc_1;
                            background = payment_payment_lc_1;
                            switch (parseInt(product.xh1)) {
                                case 1:
                                    background1 = payment_noodle_lc_1;
                                    background = payment_payment_lc_1;
                                    break;
                                case 2:
                                    background1 = payment_noodle_lc_2;
                                    background = payment_payment_lc_2;
                                    break;
                                case 3:
                                    background1 = payment_noodle_lc_3;
                                    background = payment_payment_lc_3;
                                    break;
                                case 4:
                                    background1 = payment_noodle_lc_4;
                                    background = payment_payment_lc_4;
                                    break;
                                case 5:
                                    background1 = payment_noodle_lc_5;
                                    background = payment_payment_lc_5;
                                    break;
                                case 6:
                                    background1 = payment_noodle_lc_6;
                                    background = payment_payment_lc_6;
                                    break;
                                case 7:
                                    background1 = payment_noodle_lc_7;
                                    background = payment_payment_lc_7;
                                    break;
                                case 8:
                                    background1 = payment_noodle_lc_8;
                                    background = payment_payment_lc_8;
                                    break;
                                case 9:
                                    background1 = payment_noodle_lc_9;
                                    background = payment_payment_lc_9;
                                    break;
                                case 10:
                                    background1 = payment_noodle_lc_10;
                                    background = payment_payment_lc_10;
                                    break;
                            }
                        } else if (product.noodle === "sdk-ad-noodle-ggs") {
                            background1 = payment_noodle_ggs_1;
                            background = payment_payment_ggs_1;
                            switch (parseInt(product.xh1)) {
                                case 1:
                                    background1 = payment_noodle_ggs_1;
                                    background = payment_payment_ggs_1;
                                    break;
                                case 2:
                                    background1 = payment_noodle_ggs_2;
                                    background = payment_payment_ggs_2;
                                    break;
                                case 3:
                                    background1 = payment_noodle_ggs_3;
                                    background = payment_payment_ggs_3;
                                    break;
                                case 4:
                                    background1 = payment_noodle_ggs_4;
                                    background = payment_payment_ggs_4;
                                    break;
                                case 5:
                                    background1 = payment_noodle_ggs_5;
                                    background = payment_payment_ggs_5;
                                    break;
                                case 6:
                                    background1 = payment_noodle_ggs_6;
                                    background = payment_payment_ggs_6;
                                    break;
                                case 7:
                                    background1 = payment_noodle_ggs_7;
                                    background = payment_payment_ggs_7;
                                    break;
                                case 8:
                                    background1 = payment_noodle_ggs_8;
                                    background = payment_payment_ggs_8;
                                    break;
                            }
                        }
                    }
                    let color = "white";
                    if (product.color) {
                        color = product.color;
                    }
                    let span1 = {
                        color: color,
                        top: '14%',
                        left: '34%',
                        fontSize: '20px',
                        fontWeight: 'bolder',
                        position: 'absolute',
                        textAlign: 'left'
                    };
                    if ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) {
                        span1 = {
                            color: color,
                            top: '10%',
                            left: '34%',
                            fontSize: '20px',
                            fontWeight: 'bolder',
                            position: 'absolute',
                            textAlign: 'left'
                        };
                    }

                    //  console.log(idx);
                    let float1 = "left";
                    if ((idx + idxxh) % 2 !== 0) float1 = "right";
                    let width1 = "100%";
                    if (mode === "LANDSCAPE") {
                        width1 = "48%";
                    }

                    let width2 = "90px";
                    let right1 = "6%";
                    let left1 = "15%";
                    if (typeof window !== `undefined`) {
                        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                        let wh = width;
                        if (height < wh) wh = height;
                        if (wh < 375) {
                            span1 = {
                                color: color,
                                top: '12%',
                                left: '34%',
                                fontSize: '16px',
                                fontWeight: 'bolder',
                                position: 'absolute',
                                textAlign: 'left'
                            };
                            if ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) {
                                span1 = {
                                    color: color,
                                    top: '4%',
                                    left: '34%',
                                    fontSize: '16px',
                                    fontWeight: 'bolder',
                                    position: 'absolute',
                                    textAlign: 'left'
                                };
                            }
                            width2 = "70px";
                            right1 = "2%";
                        }
                    }
                    if ((mode === "LANDSCAPE") && ((product.isNoodle1) || (product.isNoodle2) || (product.xh))) {
                        return (
                            <div key={product.productId} style={{ "marginTop": "1em", width: "100%" }} onClick={() => onSelect(product.productId)}>
                                {
                                    ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) && (
                                        <>
                                            <div style={{
                                                float: "left",
                                                width: "50%",
                                                position: 'relative', "margin": "0em 0em -0.3em 0em"
                                            }}>
                                                <img src={background1} style={{ width: "100%", height: "100%" }} alt={""} />

                                                {(product.xh <= 4) && (product.background === "payment") && (<>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "31%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "2",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }} >
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "1"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "31%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "1",
                                                        WebkitTextStroke: "5px #8f0808",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }}>
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "1"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>

                                                    </div>
                                                </>)}
                                                {(product.xh <= 10) && (product.background === "payment") && (<>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "44%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "2",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }} >
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "2"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "44%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "1",
                                                        WebkitTextStroke: `5px ${(product.xh > 4) ? "#8f0808" : "#422400"}`,
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }}>
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "2"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>

                                                    </div></>)}



                                                {(product.xh <= 15) && (product.background === "payment") && (<>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "57%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "2",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }} >
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "3"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "57%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "1",
                                                        WebkitTextStroke: `5px ${(product.xh > 10) ? "#8f0808" : "#422400"}`,
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }}>
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "3"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>

                                                    </div></>)}


                                                {(product.xh <= 23) && (product.background === "payment") && (<>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "70.5%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "2",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }} >
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "4"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "70.5%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "1",
                                                        WebkitTextStroke: `5px ${(product.xh > 15) ? "#8f0808" : "#422400"}`,
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }}>
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "4"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>

                                                    </div></>)}


                                                {(product.xh <= 30) && (product.background === "payment") && (<>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "84%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "2",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }} >
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "5"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "84%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "1",
                                                        WebkitTextStroke: `5px ${(product.xh > 23) ? "#8f0808" : "#422400"}`,
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }}>
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "5"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>

                                                    </div>
                                                </>)}

                                            </div>
                                        </>)
                                }
                                <Card fluid
                                    style={{
                                        float: "left",
                                        width: "50%",
                                        "margin": 0,
                                        backgroundColor: "transparent",
                                        boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                    }}>
                                    <span style={{
                                        top: '14%', left: '6%', position: "absolute", zIndex: '100', fontWeight: 'bolder', fontSize: '16px', color: "#FFF",
                                        textShadow: "1px 1px #1f0301,-1px -1px #1f0301,1px -1px #1f0301,-1px 1px #1f0301"
                                    }}>{xh}</span>
                                    <img src={background} style={{ width: "100%" }} alt={""} />
                                    <img src={product.imageUrl.replace(".jpg", ".png")}
                                        style={{
                                            height: "100%",
                                            position: "absolute",
                                            top: '0%',
                                            left: '0%'
                                        }} alt={""} />
                                    <div style={span1}>
                                        {(product.isCard !== 1) && (<>{hasEvent ? product.gold + product.bonusGold.holiday : product.goldbase} 幣<span style={{
                                            fontSize: '16px',
                                            color: '#ccc',
                                            textDecoration: 'line-through'
                                        }}>{bonusGoldMessage}</span>
                                        </>)}
                                        {(product.isCard === 1) && (<div style={{ marginTop: "0%", fontSize: "0.8em" }}>每日獲得{product.daygold} 幣 ({product.daycount}天)<br />
                                            累計共 {product.gold + product.bonusGold.holiday} 幣</div>)}
                                    </div>
                                    {(product.isCard !== 1) && (<span
                                        style={{
                                            color: '#fff',
                                            bottom: `${((product.isNoodle1) || (product.isNoodle2) || (product.xh)) ? "43%" : "35%"}`,
                                            left: '33%',
                                            fontSize: '20px',
                                            fontWeight: 'bolder',
                                            position: 'absolute',
                                            textAlign: 'center',
                                            paddingTop: '4px',
                                            width: `${width2}`,
                                            height: '30px',
                                            backgroundColor: bonusGoldMessagehint === "" ? "" : "#df0000",
                                            opacity: '1'
                                        }}> {bonusGoldMessagehint}</span>)}
                                    {(product.isCard === 1) && (<span
                                        style={{
                                            color: '#fff',
                                            bottom: "18%",
                                            left: '33%',
                                            fontSize: '20px',
                                            fontWeight: 'bolder',
                                            position: 'absolute',
                                            textAlign: 'center',
                                            paddingTop: '4px',
                                            width: `${width2}`,
                                            height: '30px',
                                            backgroundColor: bonusGoldMessagehint === "" ? "" : "#df0000",
                                            opacity: '1'
                                        }}> {bonusGoldMessagehint}</span>)}

                                    <span style={{
                                        color: 'white',
                                        bottom: `${((product.isNoodle1) || (product.isNoodle2) || (product.xh)) ? "18%" : "10%"}`,
                                        right: `${right1}`,
                                        fontSize: '20px',
                                        fontWeight: 'bolder',
                                        position: 'absolute',
                                        textAlign: 'right',
                                    }}>
                                        {currency === "USD" ? parseFloat((product.prices[currency] - 0.5).toFixed(2)) : product.prices[currency]} {keyToDisplay(currency)}
                                    </span>
                                </Card>
                                {
                                    (product.showTime === 1) && (
                                        <>
                                            <div style={{
                                                width: "100%",
                                                position: 'relative', "margin": "0em 0em -0.3em 0em"
                                            }}>
                                                <img src={timeSpace} style={{ width: "100%", height: "22%" }} alt={""} />
                                                <span style={{
                                                    width: "100%",
                                                    color: "white",
                                                    top: -30,
                                                    left: 0,
                                                    position: "relative",
                                                    textAlign: "center",
                                                    verticalAlign: "middle"
                                                }}>
                                                    <Timer product={product} />
                                                </span>
                                            </div>
                                        </>)
                                }
                                {(
                                    <div style={{
                                        width: "100%",
                                        height: "2px",
                                        backgroundImage: `url(${iconline})`, backgroundSize: '100% 100%',
                                        backgroundColor: "#8B0502"
                                    }} >&nbsp;</div>
                                )}
                            </div>

                        )
                    } else {
                        return (
                            <div key={product.productId} style={{ "margin": "3px 0", width: `${width1}`, float: `${float1}` }} onClick={() => onSelect(product.productId)}>
                                {
                                    ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) && (
                                        <>
                                            <div style={{
                                                position: 'relative', "margin": "0em 0em -0.3em 0em"
                                            }}>
                                                <img src={background1} style={{ width: "100%", height: "100%" }} alt={""} />
                                                {(product.xh <= 4) && (product.background === "payment") && (<>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "31%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "2",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }} >
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "1"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "31%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "1",
                                                        WebkitTextStroke: "5px #8f0808",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }}>
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "1"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>

                                                    </div>
                                                </>)}
                                                {(product.xh <= 10) && (product.background === "payment") && (<>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "44%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "2",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }} >
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "2"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "44%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "1",
                                                        WebkitTextStroke: `5px ${(product.xh > 4) ? "#8f0808" : "#422400"}`,
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }}>
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "2"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>

                                                    </div></>)}



                                                {(product.xh <= 15) && (product.background === "payment") && (<>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "57%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "2",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }} >
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "3"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "57%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "1",
                                                        WebkitTextStroke: `5px ${(product.xh > 10) ? "#8f0808" : "#422400"}`,
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }}>
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "3"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>

                                                    </div></>)}


                                                {(product.xh <= 23) && (product.background === "payment") && (<>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "70.5%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "2",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }} >
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "4"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "70.5%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "1",
                                                        WebkitTextStroke: `5px ${(product.xh > 15) ? "#8f0808" : "#422400"}`,
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }}>
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "4"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>

                                                    </div></>)}


                                                {(product.xh <= 30) && (product.background === "payment") && (<>
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "84%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "2",
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }} >
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "5"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>
                                                    </div>


                                                    <div style={{
                                                        position: 'absolute',
                                                        top: "43%",
                                                        left: "84%",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                        zIndex: "1",
                                                        WebkitTextStroke: `5px ${(product.xh > 23) ? "#8f0808" : "#422400"}`,
                                                        color: "#fff",
                                                        transform: "scale(0.5)"
                                                    }}>
                                                        <div style={{ float: "left", fontSize: "16px" }}>
                                                            {_font[currency + "5"]}
                                                        </div>
                                                        <div style={{ float: "left", fontSize: "10px" }}>
                                                            {(currency === "CNY") ? "RMB" : currency}
                                                        </div>

                                                    </div>
                                                </>)}


                                            </div>
                                        </>)
                                }
                                <Card fluid
                                    style={{
                                        "margin": 0,
                                        backgroundColor: "transparent",
                                        boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent'
                                    }}>
                                    <span style={{
                                        top: '14%', left: '6%', position: "absolute", zIndex: '100', fontWeight: 'bolder', fontSize: '16px', color: "#FFF",
                                        textShadow: "1px 1px #1f0301,-1px -1px #1f0301,1px -1px #1f0301,-1px 1px #1f0301"
                                    }}>{xh}</span>
                                    <img src={background} style={{ width: "100%" }} alt={""} />

                                    <img src={product.imageUrl.replace(".jpg", ".png")}
                                        style={{
                                            height: "100%",
                                            position: "absolute",
                                            top: '0%',
                                            left: '0%'
                                        }} alt={""} />
                                    <div style={span1}>
                                        {(product.isCard !== 1) && (<>{hasEvent ? product.gold + product.bonusGold.holiday : product.goldbase} 幣<span style={{
                                            fontSize: '16px',
                                            color: '#ccc',
                                            textDecoration: 'line-through'
                                        }}>{bonusGoldMessage}</span>
                                        </>)}
                                        {(product.isCard === 1) && (<div style={{ marginTop: "0%", fontSize: "16px" }}>每日獲得{product.daygold} 幣 ({product.daycount}天)<br />
                                            累計共 {product.gold + product.bonusGold.holiday} 幣</div>)}
                                    </div>
                                    {(product.isCard !== 1) && (<span
                                        style={{
                                            color: '#fff',
                                            bottom: `${((product.isNoodle1) || (product.isNoodle2) || (product.xh)) ? "43%" : "35%"}`,
                                            left: '33%',
                                            fontSize: '20px',
                                            fontWeight: 'bolder',
                                            position: 'absolute',
                                            textAlign: 'center',
                                            paddingTop: '4px',
                                            width: `${width2}`,
                                            height: '30px',
                                            backgroundColor: bonusGoldMessagehint === "" ? "" : "#df0000",
                                            opacity: '1'
                                        }}> {bonusGoldMessagehint}</span>)}

                                    {(product.isCard === 1) && (<span
                                        style={{
                                            color: '#fff',
                                            bottom: "18%",
                                            left: '33%',
                                            fontSize: '20px',
                                            fontWeight: 'bolder',
                                            position: 'absolute',
                                            textAlign: 'center',
                                            paddingTop: '4px',
                                            width: `${width2}`,
                                            height: '30px',
                                            backgroundColor: bonusGoldMessagehint === "" ? "" : "#df0000",
                                            opacity: '1'
                                        }}> {bonusGoldMessagehint}</span>)}

                                    <span style={{
                                        color: 'white',
                                        bottom: `${((product.isNoodle1) || (product.isNoodle2) || (product.xh)) ? "18%" : "10%"}`,
                                        right: `${right1}`,
                                        fontSize: '20px',
                                        fontWeight: 'bolder',
                                        position: 'absolute',
                                        textAlign: 'right',
                                    }}>
                                        {currency === "USD" ? parseFloat((product.prices[currency] - 0.5).toFixed(2)) : product.prices[currency]} {keyToDisplay(currency)}
                                    </span>
                                </Card>
                                {
                                    (product.showTime === 1) && (
                                        <>
                                            <div style={{ position: 'relative', "margin": "0em 0em -0.3em 0em" }}>
                                                <img src={timeSpace} style={{ width: "100%" }} alt={""} />
                                                <span style={{
                                                    width: "100%",
                                                    color: "white",
                                                    top: 0,
                                                    left: 0,
                                                    position: "absolute",
                                                    paddingTop: "5px",
                                                    textAlign: "center",
                                                    verticalAlign: "middle"
                                                }}>
                                                    <Timer product={product} />
                                                </span>
                                            </div>
                                            <div style={{
                                                marginTop: "4px",
                                                width: "100%",
                                                height: "2px",
                                                backgroundImage: `url(${iconline})`, backgroundSize: '100% 100%',
                                                backgroundColor: "#8B0502"
                                            }} >&nbsp;</div>
                                        </>)
                                }

                            </div>

                        );
                    }
                })}
            </div>
        </React.Fragment>
    );
};

function getBjTime() {
    let d = new Date();
    let local = d.getTime();
    let offset = d.getTimezoneOffset() * 60000;
    let localUtc = new Date().getTimezoneOffset() / 60;
    let utcTime;
    if (localUtc > 0) {
        utcTime = local - offset;
    } else {
        utcTime = local + offset;
    }
    let localTime = utcTime + 3600000 * Math.abs(8);
    return localTime;
}

function showHint(seconds) {
    const seconds1 = 1000;
    const minutes = seconds1 * 60;
    const hour = minutes * 60;
    const day = hour * 24;
    let iDay = parseInt(seconds / day);
    let d = seconds - iDay * day;
    let iHour = parseInt(d / hour);
    d = seconds - iDay * day - iHour * hour;
    let iMinutes = parseInt(d / minutes);
    d = seconds - iDay * day - iHour * hour - iMinutes * minutes;
    let iseconds = parseInt(d / seconds1);
    let showTime1 = "剩餘時間: ";
    if (iDay > 0) {
        showTime1 = showTime1 + iDay + "天";
    }
    if (iHour > 0) {
        showTime1 = showTime1 + iHour + "小時";
    }
    if (iMinutes > 0) {
        showTime1 = showTime1 + iMinutes + "分";
    }
    if (iseconds > 0) {
        showTime1 = showTime1 + iseconds + "秒";
    }
    if (seconds <= 1000) {
        showTime1 = "已過期";
    }
    let color = "#ffffff";
    if (seconds < 60000) {
        color = "#ff0000";
    }
    return "<span style='color:" + color + "'>" + showTime1 + "</span>";
}

class Timer extends Component {
    state = {
        seconds: 0,
        showTime: ''
    };

    tick = () => {
        const { seconds } = this.state;
        let showTime1 = showHint(seconds);

        this.setState({
            seconds: seconds - 1000,
            showTime: showTime1
        })
    }

    componentDidMount() {
        // 定时器，可以修改1000为自己想要的时间，
        const { product } = this.props;
        this.state.seconds = product.endTime - getBjTime();

        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        // 清除定时器
        clearInterval(this.interval);
        this.setState = () => false;
    }
    render() {
        const { product } = this.props;
        this.state.seconds = product.endTime - getBjTime();
        this.state.showTime = showHint(this.state.seconds);

        return (
            <div style={{ fontSize: '1.25em' }} dangerouslySetInnerHTML={{ __html: this.state.showTime }}></div>
        )
    }
}