import React, {Component, createRef, Fragment} from "react";
import {Form} from "semantic-ui-react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import PAButtonBase from "./pa-button-base";
import PropTypes from 'prop-types';
import p99uptw2 from "./p99/images/p99-uptw2.png"

class CreditPurchaseButtonP99 extends Component {
    constructor(props, context) {
        super(props, context);
        this.trigger = createRef();
        this.state = {
            clicked: false,
            dataMessage: ""
        };
    }

    handleClick() {
        if(this.state.clicked) {
            console.log("Already clicked!");
            return;
        }
        this.setState({
            clicked: true
        });
        new KaisakuUserApi(this.props.session).createTransactionP99(this.props.product.productId, this.props.currency, this.props.paid, this.props.gameId, this.props.attributionId, this.props.transactionId).then(result => {
            if (result) {
                console.log(result);
                const {dataMessage} = result;
                this.setState({
                    dataMessage
                });
                this.trigger.current.click();
            }
        });
    }

    render() {

        let image = this.props.image;
        if ((this.props.currency === "CNY") && (this.props.paid === "BNK82204")){
        const list = [
            "6c7b59f2-5922-411b-0729-5332c0ddcb02",
            "6c7b59f2-5922-411b-0729-5332c0ddea02",
            "d1747cc0-b347-46ba-0729-680011223302",
            "ae5eb5b7-fcfc-4628-0729-749676c22702",
            "ae5eb5b7-fcfc-4628-0729-749676c25b02",
            "74f12a51-18c4-488a-0729-ca0e73348d02",
            "64b51ae5-24c0-42db-97a4-eaf444160002",
            "ed744519-0c1b-43a0-8704-2b3b075f0002",
            "a043fc6e-e0de-4f96-8d5c-2fdbc6090002",

            "47bcf735-6d24-4100-0729-c0e50b74b502",
            "5264a4c9-5a8f-47e3-0729-c4d9c0ddfe02",
            "f01f1a69-d7d4-4255-0729-7f06a0904a02",
            "6c7b59f2-5922-411b-0729-5332c0ddcb02",
            "ae5eb5b7-fcfc-4628-0729-749676c22702",
            "2ee475a6-e327-402a-0729-b3c9d182de02",
            "8026d567-d19f-4507-bedd-e6c6e3190002",
            "28dce5a7-2029-446a-90dc-c31ad1410002",
            "c83083e6-edf6-4bd6-bd7e-027a51d00002",

            "d2f871fa-485e-4953-2022-c434df7b8f6e",
            "149a7e09-7c8e-4601-2022-6a80f453ada0",
            "40ddc41c-48ab-42bd-2022-f3e1bd1ff6f5",
            "7189ffe3-b4dc-4d7f-2022-63230c784d37",
            "81d0d75c-d00c-4aa1-2022-39bf7b0d2608",
            "46b7044e-6d24-4de3-2022-a8aad45d0aa0"
            ];
        if (
            list.includes(this.props.product.productId)
           ){
            image = p99uptw2; 
        }
        }

        return (
            <Fragment>
                <Form method="post"
                      action={process.env.P99OrderUrl}>
                    <input type="hidden" name="data" value={this.state.dataMessage}/>
                    <input type="submit" style={{display: "none"}}
                           ref={this.trigger}/>
                </Form>
                <PAButtonBase price={this.props.product.prices[this.props.currency]}
                              src={image}
                              disabled={this.props.disabled}
                              hidden={this.props.disabled}
                              onClick={this.handleClick.bind(this)}/>
            </Fragment>
        );

    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonP99);

CreditPurchaseButtonP99.propTypes = {
    image: PropTypes.string.isRequired,
    paid: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired
};