import React, {Component} from "react";
import {navigate} from "../../../../../../.cache/gatsby-browser-entry";
import KaisakuApi from "../../../../../controllers/kaisaku-api";
import {Button, Dimmer, Header, Image, Loader, Modal} from "semantic-ui-react";
import {secondsToString} from "../../../../../lib/time-util";

export class QRModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            expireIn: 180,
        };
    }

    onOpen() {
        this.startExpireCountdown();
    }

    cleanupTimers() {
        clearInterval(this.expireTimer);
    }

    componentWillUnmount() {
        this.cleanupTimers();
    }

    startExpireCountdown() {
        this.setState({expireIn: this.props.sec});
        this.expireTimer = setInterval(() => {
            let {expireIn} = this.state;
            if (expireIn > 0){
                console.log(--expireIn);
                this.setState({expireIn});
            }
        }, 1000);
    }

    render() {
        return (
            <Modal
                size={"mini"}
                open={this.props.open}
                onMount={this.onOpen.bind(this)}
                style={{width:"90vw"}}
            >
                <Modal.Content image>
                    <Modal.Description>
                        <Header style={{width:"100%",textAlign:"left", fontSize:"1.1rem"}}>親，支付冷卻中，請於倒計時結束後【{secondsToString(this.state.expireIn)}】，重新點擊~</Header>
                        <p></p>
                    </Modal.Description>
                    <Modal.Actions style={{textAlign:"center"}}>
                        <Button
                            style={{background:"green"}}
                            negative
                            onClick={() => {
                                this.cleanupTimers();
                                this.props.onClose();
                            }}>確認</Button>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        );
    }
}