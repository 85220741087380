import React, { Component } from "react";
import i18next from "i18next";
import { Button, Card, Dimmer, Grid, GridColumn, Loader, } from "semantic-ui-react";
import bar from "../../../images/bar2.jpg";
import { keyToDisplay } from "../../lib/currency-button-base";
import { getValidButtonData } from "../../credit-purchase/pa-buttons/buttonData";
import { CreditPurchaseButton } from "../../credit-purchase/pa-buttons/credit-purchase-button";
import eventBar2 from "../../../images/payment/event-bar.png";
import eventBar2Vip from "../../../images/payment/event-barVip.png";
import eventBar2Vip1 from "../../../images/payment/event-barVip1.png";
import eventBar2Vip2 from "../../../images/payment/event-barVip2.png";
import eventBar2Vip3 from "../../../images/payment/event-barVip3.png";
import { navigate } from "gatsby";

import timeSpace from "../../../images/TimeSpace.jpg";

import payment from "../../../images/payment.jpg";

import payment_step1 from "../../../images/step1.jpg";
import payment_step2 from "../../../images/step2.jpg";
import payment_step3 from "../../../images/step3.jpg";
import payment_step4 from "../../../images/step4.jpg";
import payment_step5 from "../../../images/step5.jpg";
import payment_step6 from "../../../images/step6.jpg";
import payment_step7 from "../../../images/step7.jpg";
import payment_step8 from "../../../images/step8.jpg";
import payment_step9 from "../../../images/step9.jpg";
import payment_step10 from "../../../images/step10.jpg";
import payment_step11 from "../../../images/step11.jpg";
import payment_step12 from "../../../images/step12.jpg";
import payment_step13 from "../../../images/step13.jpg";
import payment_step14 from "../../../images/step14.jpg";
import payment_step15 from "../../../images/step15.jpg";
import payment_step16 from "../../../images/step16.jpg";
import payment_step17 from "../../../images/step17.jpg";
import payment_step18 from "../../../images/step18.jpg";
import payment_step19 from "../../../images/step19.jpg";
import payment_step20 from "../../../images/step20.jpg";
import payment_step21 from "../../../images/step21.jpg";
import payment_step22 from "../../../images/step22.jpg";
import payment_step23 from "../../../images/step23.jpg";
import payment_step24 from "../../../images/step24.jpg";
import payment_step25 from "../../../images/step25.jpg";
import payment_step26 from "../../../images/step26.jpg";
import payment_step27 from "../../../images/step27.jpg";
import payment_step28 from "../../../images/step28.jpg";
import payment_step29 from "../../../images/step29.jpg";
import payment_step30 from "../../../images/step30.jpg";


import ofollow1 from "../../../images/200.jpg";
import ofollow2 from "../../../images/461.jpg";
import ofollow3 from "../../../images/935.jpg";
import ofollow4 from "../../../images/1398.jpg";
import ofollow5 from "../../../images/1784.jpg";
import ofollow6 from "../../../images/2383.jpg";
import ofollow7 from "../../../images/3200.jpg";
import ofollow8 from "../../../images/4615.jpg";
import ofollow9 from "../../../images/6080.jpg";

import ufollow1 from "../../../images/194.png";
import ufollow2 from "../../../images/447.png";
import ufollow3 from "../../../images/907.png";
import ufollow4 from "../../../images/1356.png";
import ufollow5 from "../../../images/1730.png";
import ufollow6 from "../../../images/2312.png";
import ufollow7 from "../../../images/3104.png";
import ufollow8 from "../../../images/4477.png";
import ufollow9 from "../../../images/5898.png";

import follow1_188 from "../../../images/188.png";
import follow2_433 from "../../../images/433.png";
import follow3_879 from "../../../images/879.png";
import follow4_1314 from "../../../images/1314.png";
import follow5_1713 from "../../../images/1713.png";
import follow6_2288 from "../../../images/2288.png";
import follow7_3072 from "../../../images/3072.png";
import follow8_4430 from "../../../images/4430.png";
import follow9_5837 from "../../../images/5837.png";


import follow1 from "../../../images/190.png";
import follow2 from "../../../images/438.png";
import follow3 from "../../../images/888.png";
import follow4 from "../../../images/1328.png";
import follow5 from "../../../images/1695.jpg";
import follow6 from "../../../images/2264.jpg";
import follow7 from "../../../images/3040.jpg";
import follow8 from "../../../images/4384.jpg";
import follow9 from "../../../images/5776.jpg";
import follow10 from "../../../images/5190.jpg";

import follow11 from "../../../images/1388.png";
import follow12 from "../../../images/2188.png";
import follow13 from "../../../images/3688.png";

import followcircle from "../../../images/followcircle.png";

import payment_noodle from "../../../images/Artboard-noodle.jpg";
import payment_payment from "../../../images/Artboard-payment.jpg";
import payment_noodle_j4 from "../../../images/Artboard-noodle-j4.jpg";
import payment_payment_j4 from "../../../images/Artboard-payment-j4.jpg";
import payment_noodle_unionpay from "../../../images/Artboard-noodle-unionpay.jpg";
import payment_payment_unionpay from "../../../images/Artboard-payment-unionpay.jpg";

import payment_noodle_nw_1 from "../../../images/payment_noodle_nw_1.jpg";
import payment_noodle_nw_2 from "../../../images/payment_noodle_nw_2.jpg";
import payment_noodle_nw_3 from "../../../images/payment_noodle_nw_3.jpg";
import payment_noodle_nw_4 from "../../../images/payment_noodle_nw_4.jpg";
import payment_payment_nw_1 from "../../../images/payment_payment_nw_1.jpg";
import payment_payment_nw_2 from "../../../images/payment_payment_nw_2.jpg";
import payment_payment_nw_3 from "../../../images/payment_payment_nw_3.jpg";
import payment_payment_nw_4 from "../../../images/payment_payment_nw_4.jpg";

import payment_noodle_nw_5 from "../../../images/payment_noodle_nw_5.jpg";
import payment_payment_nw_5 from "../../../images/payment_payment_nw_5.jpg";
import payment_noodle_nw_6 from "../../../images/payment_noodle_nw_6.jpg";
import payment_payment_nw_6 from "../../../images/payment_payment_nw_6.jpg";
import payment_noodle_nw_7 from "../../../images/payment_noodle_nw_7.jpg";
import payment_payment_nw_7 from "../../../images/payment_payment_nw_7.jpg";

import payment_noodle_bc_1 from "../../../images/payment_noodle_bc_1.jpg";
import payment_noodle_bc_2 from "../../../images/payment_noodle_bc_2.jpg";
import payment_noodle_bc_3 from "../../../images/payment_noodle_bc_3.jpg";
import payment_noodle_bc_4 from "../../../images/payment_noodle_bc_4.jpg";
import payment_noodle_bc_5 from "../../../images/payment_noodle_bc_5.jpg";
import payment_noodle_bc_6 from "../../../images/payment_noodle_bc_6.jpg";
import payment_noodle_bc_7 from "../../../images/payment_noodle_bc_7.jpg";
import payment_payment_bc from "../../../images/payment_payment_bc.jpg";

import payment_noodle_fr_1 from "../../../images/payment_noodle_fr_1.jpg";
import payment_noodle_fr_2 from "../../../images/payment_noodle_fr_2.jpg";
import payment_noodle_fr_3 from "../../../images/payment_noodle_fr_3.jpg";
import payment_noodle_fr_4 from "../../../images/payment_noodle_fr_4.jpg";
import payment_payment_fr from "../../../images/payment_payment_fr.jpg";

import payment_noodle_nu_1 from "../../../images/payment_noodle_nu_1.jpg";
import payment_noodle_nu_2 from "../../../images/payment_noodle_nu_2.jpg";
import payment_noodle_nu_3 from "../../../images/payment_noodle_nu_3.jpg";
import payment_noodle_nu_4 from "../../../images/payment_noodle_nu_4.jpg";
import payment_noodle_nu_5 from "../../../images/payment_noodle_nu_5.jpg";
import payment_noodle_nu_6 from "../../../images/payment_noodle_nu_6.jpg";
import payment_noodle_nu_7 from "../../../images/payment_noodle_nu_7.jpg";
import payment_noodle_nu_8 from "../../../images/payment_noodle_nu_8.jpg";
import payment_noodle_nu_9 from "../../../images/payment_noodle_nu_9.jpg";
import payment_noodle_nu_10 from "../../../images/payment_noodle_nu_10.jpg";
import payment_noodle_nu_11 from "../../../images/payment_noodle_nu_11.jpg";
import payment_noodle_nu_12 from "../../../images/payment_noodle_nu_12.jpg";
import payment_noodle_nu_13 from "../../../images/payment_noodle_nu_13.jpg";
import payment_noodle_nu_14 from "../../../images/payment_noodle_nu_14.jpg";
import payment_payment_nu from "../../../images/payment_payment_nu.jpg";
import payment_payment_pp from "../../../images/payment_payment_pp.jpg";

import payment_noodle_vip_1 from "../../../images/Artboard-noodle-vip-1.jpg";
import payment_noodle_vip_2 from "../../../images/Artboard-noodle-vip-2.jpg";
import payment_noodle_vip_3 from "../../../images/Artboard-noodle-vip-3.jpg";
import payment_noodle_vip_4 from "../../../images/Artboard-noodle-vip-4.jpg";
import payment_noodle_vip_5 from "../../../images/Artboard-noodle-vip-5.jpg";
import payment_noodle_vip_6 from "../../../images/Artboard-noodle-vip-6.jpg";
import payment_noodle_vip_7 from "../../../images/Artboard-noodle-vip-7.jpg";
import payment_noodle_vip_8 from "../../../images/Artboard-noodle-vip-8.jpg";
import payment_noodle_vip_9 from "../../../images/Artboard-noodle-vip-9.jpg";
import payment_noodle_vip_10 from "../../../images/Artboard-noodle-vip-10.jpg";
import payment_noodle_vip_11 from "../../../images/Artboard-noodle-vip-11.jpg";
import payment_noodle_vip_12 from "../../../images/Artboard-noodle-vip-12.jpg";
import payment_noodle_vip_13 from "../../../images/Artboard-noodle-vip-13.jpg";
import payment_noodle_vip_14 from "../../../images/Artboard-noodle-vip-14.jpg";
import payment_noodle_vip_15 from "../../../images/Artboard-noodle-vip-15.jpg";
import payment_noodle_vip_16 from "../../../images/Artboard-noodle-vip-16.jpg";
import payment_noodle_vip_17 from "../../../images/Artboard-noodle-vip-17.jpg";
import payment_payment_vip from "../../../images/Artboard-payment-vip.jpg";

import payment_payment_ml from "../../../images/noodles/4/Artboard-payment-ml.jpg";
import payment_noodle_ml_1 from "../../../images/noodles/4/Artboard-noodle-ml-1.jpg";
import payment_noodle_ml_2 from "../../../images/noodles/4/Artboard-noodle-ml-2.jpg";
import payment_noodle_ml_3 from "../../../images/noodles/4/Artboard-noodle-ml-3.jpg";
import payment_noodle_ml_4 from "../../../images/noodles/4/Artboard-noodle-ml-4.jpg";
import payment_noodle_ml_5 from "../../../images/noodles/4/Artboard-noodle-ml-5.jpg";


import payment_payment_ll_1 from "../../../images/noodles/9/Artboard-payment-ll-1.jpg";
import payment_payment_ll_2 from "../../../images/noodles/9/Artboard-payment-ll-2.jpg";
import payment_payment_ll_3 from "../../../images/noodles/9/Artboard-payment-ll-3.jpg";
import payment_payment_ll_4 from "../../../images/noodles/9/Artboard-payment-ll-4.jpg";
import payment_payment_ll_5 from "../../../images/noodles/9/Artboard-payment-ll-5.jpg";
import payment_payment_ll_6 from "../../../images/noodles/9/Artboard-payment-ll-6.jpg";
import payment_payment_ll_7 from "../../../images/noodles/9/Artboard-payment-ll-7.jpg";
import payment_payment_ll_8 from "../../../images/noodles/9/Artboard-payment-ll-8.jpg";

import payment_noodle_ll_1 from "../../../images/noodles/9/Artboard-noodle-ll-1.jpg";
import payment_noodle_ll_2 from "../../../images/noodles/9/Artboard-noodle-ll-2.jpg";
import payment_noodle_ll_3 from "../../../images/noodles/9/Artboard-noodle-ll-3.jpg";
import payment_noodle_ll_4 from "../../../images/noodles/9/Artboard-noodle-ll-4.jpg";
import payment_noodle_ll_5 from "../../../images/noodles/9/Artboard-noodle-ll-5.jpg";
import payment_noodle_ll_6 from "../../../images/noodles/9/Artboard-noodle-ll-6.jpg";
import payment_noodle_ll_7 from "../../../images/noodles/9/Artboard-noodle-ll-7.jpg";
import payment_noodle_ll_8 from "../../../images/noodles/9/Artboard-noodle-ll-8.jpg";

import payment_payment_lc_1 from "../../../images/noodles/9/Artboard-payment-lc-1.jpg";
import payment_payment_lc_2 from "../../../images/noodles/9/Artboard-payment-lc-2.jpg";
import payment_payment_lc_3 from "../../../images/noodles/9/Artboard-payment-lc-3.jpg";
import payment_payment_lc_4 from "../../../images/noodles/9/Artboard-payment-lc-4.jpg";
import payment_payment_lc_5 from "../../../images/noodles/9/Artboard-payment-lc-5.jpg";
import payment_payment_lc_6 from "../../../images/noodles/9/Artboard-payment-lc-6.jpg";
import payment_payment_lc_7 from "../../../images/noodles/9/Artboard-payment-lc-7.jpg";
import payment_payment_lc_8 from "../../../images/noodles/9/Artboard-payment-lc-8.jpg";
import payment_payment_lc_9 from "../../../images/noodles/9/Artboard-payment-lc-9.jpg";
import payment_payment_lc_10 from "../../../images/noodles/9/Artboard-payment-lc-10.jpg";

import payment_noodle_lc_1 from "../../../images/noodles/9/Artboard-noodle-lc-1.jpg";
import payment_noodle_lc_2 from "../../../images/noodles/9/Artboard-noodle-lc-2.jpg";
import payment_noodle_lc_3 from "../../../images/noodles/9/Artboard-noodle-lc-3.jpg";
import payment_noodle_lc_4 from "../../../images/noodles/9/Artboard-noodle-lc-4.jpg";
import payment_noodle_lc_5 from "../../../images/noodles/9/Artboard-noodle-lc-5.jpg";
import payment_noodle_lc_6 from "../../../images/noodles/9/Artboard-noodle-lc-6.jpg";
import payment_noodle_lc_7 from "../../../images/noodles/9/Artboard-noodle-lc-7.jpg";
import payment_noodle_lc_8 from "../../../images/noodles/9/Artboard-noodle-lc-8.jpg";
import payment_noodle_lc_9 from "../../../images/noodles/9/Artboard-noodle-lc-9.jpg";
import payment_noodle_lc_10 from "../../../images/noodles/9/Artboard-noodle-lc-10.jpg";

import payment_payment_ggs_1 from "../../../images/noodles/9/Artboard-payment-ggs-1.jpg";
import payment_payment_ggs_2 from "../../../images/noodles/9/Artboard-payment-ggs-2.jpg";
import payment_payment_ggs_3 from "../../../images/noodles/9/Artboard-payment-ggs-3.jpg";
import payment_payment_ggs_4 from "../../../images/noodles/9/Artboard-payment-ggs-4.jpg";
import payment_payment_ggs_5 from "../../../images/noodles/9/Artboard-payment-ggs-5.jpg";
import payment_payment_ggs_6 from "../../../images/noodles/9/Artboard-payment-ggs-6.jpg";
import payment_payment_ggs_7 from "../../../images/noodles/9/Artboard-payment-ggs-7.jpg";
import payment_payment_ggs_8 from "../../../images/noodles/9/Artboard-payment-ggs-8.jpg";

import payment_noodle_ggs_1 from "../../../images/noodles/9/Artboard-noodle-ggs-1.jpg";
import payment_noodle_ggs_2 from "../../../images/noodles/9/Artboard-noodle-ggs-2.jpg";
import payment_noodle_ggs_3 from "../../../images/noodles/9/Artboard-noodle-ggs-3.jpg";
import payment_noodle_ggs_4 from "../../../images/noodles/9/Artboard-noodle-ggs-4.jpg";
import payment_noodle_ggs_5 from "../../../images/noodles/9/Artboard-noodle-ggs-5.jpg";
import payment_noodle_ggs_6 from "../../../images/noodles/9/Artboard-noodle-ggs-6.jpg";
import payment_noodle_ggs_7 from "../../../images/noodles/9/Artboard-noodle-ggs-7.jpg";
import payment_noodle_ggs_8 from "../../../images/noodles/9/Artboard-noodle-ggs-8.jpg";

import iconline from "../../../images/main/line.png";

export default (props) => {
    const { onCancel, ready, product, followMode, currency, hasEvent, hupaystatus, mode, q } = props;
    let display = "none";
    let hintMessage = "";
    if (currency === "USD") {
        display = "flex";
        hintMessage = i18next.t("SDK.NoteThehandlingfee");
    }
    if (currency === "CNY") {
        display = "flex";
        hintMessage = "";
        // "注意：使用中國支付寶，將加收5%手續費";
    }
    if ((currency === "TWD") && (followMode !== 2)) {
        display = "flex";
        // GASH錢包不上，暫時不顯示
        hintMessage = "注意：選用GASH錢包，將加收20%手續費";
    }
    let display2 = "none";
    if ((currency === "CNY") || (currency === "HKD")) {
        display2 = "";
    }
    let _font = [];
    _font["CNY1"] = "417";
    _font["CNY2"] = "1047";
    _font["CNY3"] = "1666";
    _font["CNY4"] = "2821";
    _font["CNY5"] = "4080";

    _font["HKD1"] = "492";
    _font["HKD2"] = "1235";
    _font["HKD3"] = "1965";
    _font["HKD4"] = "3327";
    _font["HKD5"] = "4812";

    _font["TWD1"] = "1871";
    _font["TWD2"] = "4698";
    _font["TWD3"] = "7475";
    _font["TWD4"] = "12654";
    _font["TWD5"] = "18303";

    _font["USD1"] = "62.59";
    _font["USD2"] = "157.18";
    _font["USD3"] = "250.11";
    _font["USD4"] = "423.42";
    _font["USD5"] = "612.48";

    _font["MYR1"] = "294";
    _font["MYR2"] = "737";
    _font["MYR3"] = "1173";
    _font["MYR4"] = "1985";
    _font["MYR5"] = "2871";

    let _background1 = [];
    let background1 = "";
    _background1["payment_step1"] = payment_step1;
    _background1["payment_step2"] = payment_step2;
    _background1["payment_step3"] = payment_step3;
    _background1["payment_step4"] = payment_step4;
    _background1["payment_step5"] = payment_step5;
    _background1["payment_step6"] = payment_step6;
    _background1["payment_step7"] = payment_step7;
    _background1["payment_step8"] = payment_step8;
    _background1["payment_step9"] = payment_step9;
    _background1["payment_step10"] = payment_step10;
    _background1["payment_step11"] = payment_step11;
    _background1["payment_step12"] = payment_step12;
    _background1["payment_step13"] = payment_step13;
    _background1["payment_step14"] = payment_step14;
    _background1["payment_step15"] = payment_step15;
    _background1["payment_step16"] = payment_step16;
    _background1["payment_step17"] = payment_step17;
    _background1["payment_step18"] = payment_step18;
    _background1["payment_step19"] = payment_step19;
    _background1["payment_step20"] = payment_step20;
    _background1["payment_step21"] = payment_step21;
    _background1["payment_step22"] = payment_step22;
    _background1["payment_step23"] = payment_step23;
    _background1["payment_step24"] = payment_step24;
    _background1["payment_step25"] = payment_step25;
    _background1["payment_step26"] = payment_step26;
    _background1["payment_step27"] = payment_step27;
    _background1["payment_step28"] = payment_step28;
    _background1["payment_step29"] = payment_step29;
    _background1["payment_step30"] = payment_step30;




    let background = hasEvent ? eventBar2 : bar;
    let backgroundFw = hasEvent ? eventBar2 : bar;
    if (product) {
        if (product.isVip) {
            background = eventBar2Vip;
        }
        if (product.isVIPsub) {
            if (product.viplv === 1) {
                background = eventBar2Vip1;
            }else
            if (product.viplv === 2) {
                background = eventBar2Vip2;
            }else
            if (product.viplv === 3) {
                background = eventBar2Vip3;
            }
        }
        // if (product.goldbase === 200){ 
        //     backgroundFw = follow1;
        // }else if (product.goldbase === 461){
        //     backgroundFw = follow2;
        // }else 
        if (product.goldbase === 200) {
            backgroundFw = ofollow1;
        } else if (product.goldbase === 461) {
            backgroundFw = ofollow2;
        } else if (product.goldbase === 935) {
            backgroundFw = ofollow3;
        } else if (product.goldbase === 1398) {
            backgroundFw = ofollow4;
        } else if (product.goldbase === 1784) {
            backgroundFw = ofollow5;
        } else if (product.goldbase === 2383) {
            backgroundFw = ofollow6;
        } else if (product.goldbase === 3200) {
            backgroundFw = ofollow7;
        } else if (product.goldbase === 4615) {
            backgroundFw = ofollow8;
        } else if (product.goldbase === 6080) {
            backgroundFw = ofollow9;
        } else if (product.goldbase === 190) {
            backgroundFw = follow1;
        } else if (product.goldbase === 438) {
            backgroundFw = follow2;
        } else if (product.goldbase === 888) {
            backgroundFw = follow3;
        } else if (product.goldbase === 1328) {
            backgroundFw = follow4;
        } else if (product.goldbase === 1695) {
            backgroundFw = follow5;
        } else if (product.goldbase === 2264) {
            backgroundFw = follow6;
        } else if (product.goldbase === 3040) {
            backgroundFw = follow7;
        } else if (product.goldbase === 4384) {
            backgroundFw = follow8;
        } else if (product.goldbase === 5776) {
            backgroundFw = follow9;
        } else if (product.goldbase === 188) {
            backgroundFw = follow1_188;
        } else if (product.goldbase === 433) {
            backgroundFw = follow2_433;
        } else if (product.goldbase === 879) {
            backgroundFw = follow3_879;
        } else if (product.goldbase === 1314) {
            backgroundFw = follow4_1314;
        } else if (product.goldbase === 1713) {
            backgroundFw = follow5_1713;
        } else if (product.goldbase === 2288) {
            backgroundFw = follow6_2288;
        } else if (product.goldbase === 3072) {
            backgroundFw = follow7_3072;
        } else if (product.goldbase === 4430) {
            backgroundFw = follow8_4430;
        } else if (product.goldbase === 5837) {
            backgroundFw = follow9_5837;
        } else if (product.goldbase === 5190) {
            backgroundFw = follow10;
        } else if (product.goldbase === 8114) {
            backgroundFw = follow11;
        } else if (product.goldbase === 12787) {
            backgroundFw = follow12;
        } else if (product.goldbase === 21556) {
            backgroundFw = follow13;
        } else if (product.goldbase === 194) {
            backgroundFw = ufollow1;
        } else if (product.goldbase === 447) {
            backgroundFw = ufollow2;
        } else if (product.goldbase === 907) {
            backgroundFw = ufollow3;
        } else if (product.goldbase === 1356) {
            backgroundFw = ufollow4;
        } else if (product.goldbase === 1730) {
            backgroundFw = ufollow5;
        } else if (product.goldbase === 2312) {
            backgroundFw = ufollow6;
        } else if (product.goldbase === 3104) {
            backgroundFw = ufollow7;
        } else if (product.goldbase === 4477) {
            backgroundFw = ufollow8;
        } else if (product.goldbase === 5898) {
            backgroundFw = ufollow9;
        }
    }


    let span1 = {
        color: "white",
        top: '14%',
        left: '34%',
        fontSize: '20px',
        fontWeight: 'bolder',
        position: 'absolute',
        textAlign: 'left'
    };
    if (product) {
        if ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) {
            span1 = {
                color: "white",
                top: '10%',
                left: '34%',
                fontSize: '20px',
                fontWeight: 'bolder',
                position: 'absolute',
                textAlign: 'left'
            };
        }
    }


    let width2 = "90px";
    let right1 = "6%";
    let left1 = "15%";
    if (typeof window !== `undefined`) {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let wh = width;
        if (height < wh) wh = height;
        if (wh < 375) {
            span1 = {
                color: "white",
                top: '8%',
                left: '34%',
                fontSize: '16px',
                fontWeight: 'bolder',
                position: 'absolute',
                textAlign: 'left'
            };
            if (product) {
                if ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) {
                    span1 = {
                        color: "white",
                        top: '4%',
                        left: '34%',
                        fontSize: '16px',
                        fontWeight: 'bolder',
                        position: 'absolute',
                        textAlign: 'left'
                    };
                }
            }
            width2 = "70px";
            right1 = "2%";
        }
    }
    let xh = "";
    let bonusGoldMessage = "";
    let bonusGoldMessagehint = "";
    let float1 = "";

    if (product) {
        if (hasEvent) {
            if (product.bonusGold.holiday > 0) {
                if ((product.gold + product.bonusGold.holiday) !== product.goldbase) {
                    bonusGoldMessage += ` (${product.goldbase})`;
                }
            }
            if (product.bonusGold.holidayhint) {
                bonusGoldMessagehint = product.bonusGold.holidayhint;
            }
        }
        if (product.xh) {
            xh = product.xh;
            background1 = _background1[product.background + "_step" + xh];
            // xh = parseInt(xh) % 5;
            // if (xh === 0) xh = 5;
        }
        if ((product.onlyone) && (product.background)) {
            if (product.background === "payment") {
                background = payment;
            } else {
                background = product.background;
            }
        } else if (product.isNoodle1 || product.isNoodle2) {
            background1 = payment_noodle;
            background = payment_payment;
            if (product.noodle === "sdk-ad-noodle-unionpay") {
                background1 = payment_noodle_unionpay;
                background = payment_payment_unionpay;
            } else if (product.noodle === "sdk-ad-noodle-fr") {
                background = payment_payment_fr;
                switch (parseInt(product.xh1)) {
                    case 1:
                        background1 = payment_noodle_fr_1;
                        break;
                    case 2:
                        background1 = payment_noodle_fr_2;
                        break;
                    case 3:
                        background1 = payment_noodle_fr_3;
                        break;
                    case 4:
                        background1 = payment_noodle_fr_4;
                        break;
                }
            } else if (product.noodle === "sdk-ad-noodle-bc") {
                background = payment_payment_bc;
                switch (parseInt(product.xh1)) {
                    case 1:
                        background1 = payment_noodle_bc_1;
                        break;
                    case 2:
                        background1 = payment_noodle_bc_2;
                        break;
                    case 3:
                        background1 = payment_noodle_bc_3;
                        break;
                    case 4:
                        background1 = payment_noodle_bc_4;
                        break;
                    case 5:
                        background1 = payment_noodle_bc_5;
                        break;
                    case 6:
                        background1 = payment_noodle_bc_6;
                        break;
                    case 7:
                        background1 = payment_noodle_bc_7;
                        break;
                }
            } else if (product.noodle === "sdk-ad-noodle-nw") {
                switch (parseInt(product.xh1)) {
                    case 1:
                        background1 = payment_noodle_nw_1;
                        background = payment_payment_nw_1;
                        break;
                    case 2:
                        background1 = payment_noodle_nw_2;
                        background = payment_payment_nw_2;
                        break;
                    case 3:
                        background1 = payment_noodle_nw_3;
                        background = payment_payment_nw_3;
                        break;
                    case 4:
                        background1 = payment_noodle_nw_4;
                        background = payment_payment_nw_4;
                        break;
                    case 5:
                        background1 = payment_noodle_nw_5;
                        background = payment_payment_nw_5;
                        break;
                    case 6:
                        background1 = payment_noodle_nw_6;
                        background = payment_payment_nw_6;
                        break;
                    case 7:
                        background1 = payment_noodle_nw_7;
                        background = payment_payment_nw_7;
                        break;
                }

            } else if (product.noodle === "sdk-ad-noodle-nu") {
                background = payment_payment_nu;
                switch (parseInt(product.xh1)) {
                    case 1:
                        background1 = payment_noodle_nu_1;
                        break;
                    case 2:
                        background1 = payment_noodle_nu_2;
                        break;
                    case 3:
                        background1 = payment_noodle_nu_3;
                        break;
                    case 4:
                        background1 = payment_noodle_nu_4;
                        break;
                    case 5:
                        background1 = payment_noodle_nu_5;
                        break;
                    case 6:
                        background1 = payment_noodle_nu_6;
                        break;
                    case 7:
                        background1 = payment_noodle_nu_7;
                        break;
                    case 8:
                        background1 = payment_noodle_nu_8;
                        break;
                    case 9:
                        background1 = payment_noodle_nu_9;
                        break;
                    case 10:
                        background1 = payment_noodle_nu_10;
                        break;
                    case 11:
                        background1 = payment_noodle_nu_11;
                        break;
                    case 12:
                        background1 = payment_noodle_nu_12;
                        background = payment_payment_pp;
                        break;
                    case 13:
                        background1 = payment_noodle_nu_13;
                        background = payment_payment_pp;
                        break;
                    case 14:
                        background1 = payment_noodle_nu_14;
                        background = payment_payment_pp;
                        break;
                }

            } else if (product.noodle === "sdk-ad-noodle-ml") {
                background = payment_payment_ml;
                switch (parseInt(product.xh1)) {
                    case 1:
                        background1 = payment_noodle_ml_1;
                        break;
                    case 2:
                        background1 = payment_noodle_ml_2;
                        break;
                    case 3:
                        background1 = payment_noodle_ml_3;
                        break;
                    case 4:
                        background1 = payment_noodle_ml_4;
                        break;
                    case 5:
                        background1 = payment_noodle_ml_5;
                        break;
                }

            } else if (product.noodle === "sdk-ad-noodle-j4") {
                background1 = payment_noodle_j4;
                background = payment_payment_j4;
            } else if (product.noodle === "sdk-ad-noodle-vip") {
                background1 = payment_noodle_vip_1;
                switch (parseInt(product.group)) {
                    case 1:
                        background1 = payment_noodle_vip_1;
                        break;
                    case 2:
                        background1 = payment_noodle_vip_2;
                        break;
                    case 3:
                        background1 = payment_noodle_vip_3;
                        break;
                    case 4:
                        background1 = payment_noodle_vip_4;
                        break;
                    case 5:
                        background1 = payment_noodle_vip_5;
                        break;
                    case 6:
                        background1 = payment_noodle_vip_6;
                        break;
                    case 7:
                        background1 = payment_noodle_vip_7;
                        break;
                    case 8:
                        background1 = payment_noodle_vip_8;
                        break;
                    case 9:
                        background1 = payment_noodle_vip_9;
                        break;
                    case 10:
                        background1 = payment_noodle_vip_10;
                        break;
                    case 11:
                        background1 = payment_noodle_vip_11;
                        break;
                    case 12:
                        background1 = payment_noodle_vip_12;
                        break;
                    case 13:
                        background1 = payment_noodle_vip_13;
                        break;
                    case 14:
                        background1 = payment_noodle_vip_14;
                        break;
                    case 15:
                        background1 = payment_noodle_vip_15;
                        break;
                    case 16:
                        background1 = payment_noodle_vip_16;
                        break;
                    case 17:
                        background1 = payment_noodle_vip_17;
                        break;
                }
                background = payment_payment_vip;
            } else if (product.noodle === "sdk-ad-noodle-ll") {
                background1 = payment_noodle_ll_1;
                switch (parseInt(product.xh1)) {
                    case 1:
                        background1 = payment_noodle_ll_1;
                        background = payment_payment_ll_1;
                        break;
                    case 2:
                        background1 = payment_noodle_ll_2;
                        background = payment_payment_ll_2;
                        break;
                    case 3:
                        background1 = payment_noodle_ll_3;
                        background = payment_payment_ll_3;
                        break;
                    case 4:
                        background1 = payment_noodle_ll_4;
                        background = payment_payment_ll_4;
                        break;
                    case 5:
                        background1 = payment_noodle_ll_5;
                        background = payment_payment_ll_5;
                        break;
                    case 6:
                        background1 = payment_noodle_ll_6;
                        background = payment_payment_ll_6;
                        break;
                    case 7:
                        background1 = payment_noodle_ll_7;
                        background = payment_payment_ll_7;
                        break;
                    case 8:
                        background1 = payment_noodle_ll_8;
                        background = payment_payment_ll_8;
                        break;
                }
            } else if (product.noodle === "sdk-ad-noodle-lc") {
                background1 = payment_noodle_lc_1;
                background = payment_payment_lc_1;
                switch (parseInt(product.xh1)) {
                    case 1:
                        background1 = payment_noodle_lc_1;
                        background = payment_payment_lc_1;
                        break;
                    case 2:
                        background1 = payment_noodle_lc_2;
                        background = payment_payment_lc_2;
                        break;
                    case 3:
                        background1 = payment_noodle_lc_3;
                        background = payment_payment_lc_3;
                        break;
                    case 4:
                        background1 = payment_noodle_lc_4;
                        background = payment_payment_lc_4;
                        break;
                    case 5:
                        background1 = payment_noodle_lc_5;
                        background = payment_payment_lc_5;
                        break;
                    case 6:
                        background1 = payment_noodle_lc_6;
                        background = payment_payment_lc_6;
                        break;
                    case 7:
                        background1 = payment_noodle_lc_7;
                        background = payment_payment_lc_7;
                        break;
                    case 8:
                        background1 = payment_noodle_lc_8;
                        background = payment_payment_lc_8;
                        break;
                    case 9:
                        background1 = payment_noodle_lc_9;
                        background = payment_payment_lc_9;
                        break;
                    case 10:
                        background1 = payment_noodle_lc_10;
                        background = payment_payment_lc_10;
                        break;
                }
            } else if (product.noodle === "sdk-ad-noodle-ggs") {
                background1 = payment_noodle_ggs_1;
                background = payment_payment_ggs_1;
                switch (parseInt(product.xh1)) {
                    case 1:
                        background1 = payment_noodle_ggs_1;
                        background = payment_payment_ggs_1;
                        break;
                    case 2:
                        background1 = payment_noodle_ggs_2;
                        background = payment_payment_ggs_2;
                        break;
                    case 3:
                        background1 = payment_noodle_ggs_3;
                        background = payment_payment_ggs_3;
                        break;
                    case 4:
                        background1 = payment_noodle_ggs_4;
                        background = payment_payment_ggs_4;
                        break;
                    case 5:
                        background1 = payment_noodle_ggs_5;
                        background = payment_payment_ggs_5;
                        break;
                    case 6:
                        background1 = payment_noodle_ggs_6;
                        background = payment_payment_ggs_6;
                        break;
                    case 7:
                        background1 = payment_noodle_ggs_7;
                        background = payment_payment_ggs_7;
                        break;
                    case 8:
                        background1 = payment_noodle_ggs_8;
                        background = payment_payment_ggs_8;
                        break;
                }
            }
        }
        let color = "white";
        if (product.color) {
            color = product.color;
        }
        span1 = {
            color: color,
            top: '14%',
            left: '34%',
            fontSize: '20px',
            fontWeight: 'bolder',
            position: 'absolute',
            textAlign: 'left'
        };
        if (product) {
            if ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) {
                span1 = {
                    color: color,
                    top: '10%',
                    left: '34%',
                    fontSize: '20px',
                    fontWeight: 'bolder',
                    position: 'absolute',
                    textAlign: 'left'
                };
            }
        }
        if (product.showTime === 1) {
            console.log("计时器");
        }
        if (mode === "LANDSCAPE") {
            if ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) {
                float1 = "left";
            }
        }
        if (typeof window !== `undefined`) {
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            let wh = width;
            if (height < wh) wh = height;
            if (wh < 375) {
                span1 = {
                    color: color,
                    top: '8%',
                    left: '34%',
                    fontSize: '16px',
                    fontWeight: 'bolder',
                    position: 'absolute',
                    textAlign: 'left'
                };
                if (product) {
                    if ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) {
                        span1 = {
                            color: color,
                            top: '4%',
                            left: '34%',
                            fontSize: '16px',
                            fontWeight: 'bolder',
                            position: 'absolute',
                            textAlign: 'left'
                        };
                    }
                }
            }
        }
    }
    let width1 = "90%";
    let paddingleft1 = "0%";
    if (mode === "LANDSCAPE") {
        width1 = "98%";
        paddingleft1 = "0%";
    }
    return (
        <div style={{
            marginLeft: '3%', marginRight: '3%'
        }}>
            <Dimmer page active={(!ready) && (followMode !== 2)}>
                <Loader />
            </Dimmer>
            <div style={{ width: '100%', color: '#fff', paddingLeft: '20px', display: `${display}` }}>{hintMessage}</div>

            {(followMode === 2) && (mode === "PORTRAIT") && (
                <div style={{
                    width: "100%",
                    height: "2px",
                    marginBottom: "8px",
                    backgroundImage: `url(${iconline})`, backgroundSize: '100% 100%',
                    backgroundColor: "#8B0502"
                }} >&nbsp;</div>)}

            {(followMode === 2) && (mode === "LANDSCAPE") && (
                <div style={{
                    width: "100%",
                    height: "2px",
                    marginBottom: "8px",
                    backgroundImage: `url(${iconline})`, backgroundSize: '100% 100%',
                    backgroundColor: "#8B0502"
                }} >&nbsp;</div>)}
            {ready && (followMode === 2) &&
                <div style={{
                    paddingLeft: `${paddingleft1}`
                }}>
                    <div style={{
                        float: `${float1}`,
                        marginLeft: "auto",
                        marginRight: "auto",

                        position: 'relative',
                        width: `${width1}`
                    }}>

                        <img src={backgroundFw} style={{ width: "100%", position: "relative" }} alt={""} />
                        <div style={{
                            position: "absolute", top: "-5%", right: "-3%", width: "11%"
                        }}>
                            <img src={followcircle} style={{ width: "100%", position: "relative" }} alt={""}
                                onClick={() => {
                                    navigate(`/sdk-teaching3/${q}&t3re=2`);
                                }}
                            />
                        </div>
                    </div>
                </div>}
            {ready && (followMode !== 2) &&
                <div style={{
                    paddingLeft: `${paddingleft1}`
                }}>
                    {
                        ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) && (
                            <>
                                <div style={{
                                    float: `${float1}`,
                                    width: `${width1}`,

                                    position: 'relative', "margin": "0em 0em -0.3em 0em",
                                    marginLeft: "auto",
                                    marginRight: "auto"
                                }}>
                                    <img src={background1} style={{ width: "100%", height: "100%" }} alt={""} />
                                    {(product.xh <= 4) && (product.background === "payment") && (<>
                                        <div style={{
                                            position: 'absolute',
                                            top: "42%",
                                            left: "30.6%",
                                            width: "25%",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            zIndex: "2",
                                            color: "#fff",
                                            transform: "scale(0.5)"
                                        }} >
                                            <div style={{ float: "left", fontSize: "16px" }}>
                                                {_font[currency + "1"]}
                                            </div>
                                            <div style={{ float: "left", fontSize: "10px" }}>
                                                {(currency === "CNY") ? "RMB" : currency}
                                            </div>
                                        </div>


                                        <div style={{
                                            position: 'absolute',
                                            top: "42%",
                                            left: "30.6%",
                                            width: "25%",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            zIndex: "1",
                                            WebkitTextStroke: "5px #8f0808",
                                            color: "#fff",
                                            transform: "scale(0.5)"
                                        }}>
                                            <div style={{ float: "left", fontSize: "16px" }}>
                                                {_font[currency + "1"]}
                                            </div>
                                            <div style={{ float: "left", fontSize: "10px" }}>
                                                {(currency === "CNY") ? "RMB" : currency}
                                            </div>

                                        </div>
                                    </>)}
                                    {(product.xh <= 10) && (product.background === "payment") && (<>
                                        <div style={{
                                            position: 'absolute',
                                            top: "42%",
                                            left: "43.2%",
                                            width: "25%",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            zIndex: "2",
                                            color: "#fff",
                                            transform: "scale(0.5)"
                                        }} >
                                            <div style={{ float: "left", fontSize: "16px" }}>
                                                {_font[currency + "2"]}
                                            </div>
                                            <div style={{ float: "left", fontSize: "10px" }}>
                                                {(currency === "CNY") ? "RMB" : currency}
                                            </div>
                                        </div>


                                        <div style={{
                                            position: 'absolute',
                                            top: "42%",
                                            left: "43.2%",
                                            width: "25%",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            zIndex: "1",
                                            WebkitTextStroke: `5px ${(product.xh > 4) ? "#8f0808" : "#422400"}`,
                                            color: "#fff",
                                            transform: "scale(0.5)"
                                        }}>
                                            <div style={{ float: "left", fontSize: "16px" }}>
                                                {_font[currency + "2"]}
                                            </div>
                                            <div style={{ float: "left", fontSize: "10px" }}>
                                                {(currency === "CNY") ? "RMB" : currency}
                                            </div>

                                        </div></>)}



                                    {(product.xh <= 15) && (product.background === "payment") && (<>
                                        <div style={{
                                            position: 'absolute',
                                            top: "42%",
                                            left: "55.8%",
                                            width: "25%",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            zIndex: "2",
                                            color: "#fff",
                                            transform: "scale(0.5)"
                                        }} >
                                            <div style={{ float: "left", fontSize: "16px" }}>
                                                {_font[currency + "3"]}
                                            </div>
                                            <div style={{ float: "left", fontSize: "10px" }}>
                                                {(currency === "CNY") ? "RMB" : currency}
                                            </div>
                                        </div>


                                        <div style={{
                                            position: 'absolute',
                                            top: "42%",
                                            left: "55.8%",
                                            width: "25%",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            zIndex: "1",
                                            WebkitTextStroke: `5px ${(product.xh > 10) ? "#8f0808" : "#422400"}`,
                                            color: "#fff",
                                            transform: "scale(0.5)"
                                        }}>
                                            <div style={{ float: "left", fontSize: "16px" }}>
                                                {_font[currency + "3"]}
                                            </div>
                                            <div style={{ float: "left", fontSize: "10px" }}>
                                                {(currency === "CNY") ? "RMB" : currency}
                                            </div>

                                        </div></>)}


                                    {(product.xh <= 23) && (product.background === "payment") && (<>
                                        <div style={{
                                            position: 'absolute',
                                            top: "42%",
                                            left: "68.9%",
                                            width: "25%",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            zIndex: "2",
                                            color: "#fff",
                                            transform: "scale(0.5)"
                                        }} >
                                            <div style={{ float: "left", fontSize: "16px" }}>
                                                {_font[currency + "4"]}
                                            </div>
                                            <div style={{ float: "left", fontSize: "10px" }}>
                                                {(currency === "CNY") ? "RMB" : currency}
                                            </div>
                                        </div>


                                        <div style={{
                                            position: 'absolute',
                                            top: "42%",
                                            left: "68.9%",
                                            width: "25%",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            zIndex: "1",
                                            WebkitTextStroke: `5px ${(product.xh > 15) ? "#8f0808" : "#422400"}`,
                                            color: "#fff",
                                            transform: "scale(0.5)"
                                        }}>
                                            <div style={{ float: "left", fontSize: "16px" }}>
                                                {_font[currency + "4"]}
                                            </div>
                                            <div style={{ float: "left", fontSize: "10px" }}>
                                                {(currency === "CNY") ? "RMB" : currency}
                                            </div>

                                        </div></>)}


                                    {(product.xh <= 30) && (product.background === "payment") && (<>
                                        <div style={{
                                            position: 'absolute',
                                            top: "42%",
                                            left: "82%",
                                            width: "25%",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            zIndex: "2",
                                            color: "#fff",
                                            transform: "scale(0.5)"
                                        }} >
                                            <div style={{ float: "left", fontSize: "16px" }}>
                                                {_font[currency + "5"]}
                                            </div>
                                            <div style={{ float: "left", fontSize: "10px" }}>
                                                {(currency === "CNY") ? "RMB" : currency}
                                            </div>
                                        </div>


                                        <div style={{
                                            position: 'absolute',
                                            top: "42%",
                                            left: "82%",
                                            width: "25%",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            zIndex: "1",
                                            WebkitTextStroke: `5px ${(product.xh > 23) ? "#8f0808" : "#422400"}`,
                                            color: "#fff",
                                            transform: "scale(0.5)"
                                        }}>
                                            <div style={{ float: "left", fontSize: "16px" }}>
                                                {_font[currency + "5"]}
                                            </div>
                                            <div style={{ float: "left", fontSize: "10px" }}>
                                                {(currency === "CNY") ? "RMB" : currency}
                                            </div>

                                        </div>
                                    </>)}







                                </div>
                            </>)
                    }
                    <div style={{
                        float: `${float1}`,
                        marginLeft: "auto",
                        marginRight: "auto",

                        position: 'relative',
                        width: `${width1}`
                    }}>
                        <span style={{
                            top: '14%', left: '6%', position: "absolute", zIndex: '100', fontWeight: 'bolder', fontSize: '16px', color: "#FFF",
                            textShadow: "1px 1px #1f0301,-1px -1px #1f0301,1px -1px #1f0301,-1px 1px #1f0301"
                        }}>{xh}</span>
                        <img src={background} style={{ width: "100%", position: "relative" }} alt={""} />

                        <img src={product.imageUrl.replace(".jpg", ".png")}
                            style={{ height: `${product.isVIPsub?"94%":"100%"}`, position: "absolute", top: `${product.isVIPsub?"2%":"0%"}`, left: "0%" }} alt={""} />
                        <div style={span1}>
                            {(product.isCard !== 1) && (<>{hasEvent ? product.gold + product.bonusGold.holiday : product.goldbase} 幣<span style={{
                                fontSize: '16px',
                                color: '#ccc',
                                textDecoration: 'line-through'
                            }}>{bonusGoldMessage}</span>
                            </>)}
                            {(product.isCard === 1) && (<div style={{ marginTop: "0%", fontSize: "0.8em" }}>每日獲得{product.daygold} 幣 ({product.daycount}天)<br />
                                累計共 {product.gold + product.bonusGold.holiday} 幣
                            </div>)}
                        </div>
                        {(product.isCard !== 1) && (<span
                            style={{
                                color: '#fff',
                                bottom: `${((product.isNoodle1) || (product.isNoodle2) || (product.xh)) ? "45%" : "38%"}`,
                                left: '33%',
                                fontSize: '20px',
                                fontWeight: 'bolder',
                                position: 'absolute',
                                textAlign: 'center',
                                paddingTop: '4px',
                                width: `${width2}`,
                                height: '30px',
                                backgroundColor: bonusGoldMessagehint === "" ? "" : "#df0000",
                                opacity: '1'
                            }}> {bonusGoldMessagehint}</span>)}
                        {(product.isCard === 1) && (<span
                            style={{
                                color: '#fff',
                                bottom: "18%",
                                left: '33%',
                                fontSize: '20px',
                                fontWeight: 'bolder',
                                position: 'absolute',
                                textAlign: 'center',
                                paddingTop: '4px',
                                width: `${width2}`,
                                height: '30px',
                                backgroundColor: bonusGoldMessagehint === "" ? "" : "#df0000",
                                opacity: '1'
                            }}> {bonusGoldMessagehint}</span>)}
                        <span style={{
                            color: "white",
                            bottom: `${((product.isNoodle1) || (product.isNoodle2) || (product.xh)) ? "21%" : "15%"}`,
                            right: `${right1}`,
                            fontSize: "20px",
                            fontWeight: "bolder",
                            position: "absolute",
                            textAlign: "right",
                        }}>
                            {currency === "USD" ? parseFloat((product.prices[currency] - 0.5).toFixed(2)) : product.prices[currency]} {keyToDisplay(currency)}
                        </span>
                    </div>
                    {
                        (mode === "PORTRAIT") && (product.showTime === 1) && (
                            <>
                                <div style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: "-4px",
                                    width: "90%", height: "90%", position: 'relative'
                                }}>
                                    <img src={timeSpace} style={{ width: "100%" }} alt={""} />
                                    <span style={{
                                        width: "100%",
                                        color: "white",
                                        top: 0,
                                        left: 0,
                                        position: "absolute",
                                        paddingTop: "5px",
                                        textAlign: "center",
                                        verticalAlign: "middle"
                                    }}>
                                        <Timer product={product} />
                                    </span>
                                </div>
                            </>)
                    }

                    {
                        (mode === "LANDSCAPE") && (product.showTime === 1) && (
                            <>
                                <div style={{
                                    width: "98%",
                                    height: "43vh",
                                }}>
                                    <img src={timeSpace} style={{ width: "100%", height: "20%" }} alt={""} />
                                    <span style={{
                                        width: "100%",
                                        color: "white",
                                        top: -30,
                                        left: 0,
                                        position: "relative",
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                    }}>
                                        <Timer product={product} />
                                    </span>
                                </div>
                            </>)
                    }
                </div>
            }

            {ready && <PaymentAgencyList product={product} currency={currency} hupaystatus={hupaystatus} mode={mode} followMode={followMode} />}
        </div>
    );
};

function getBjTime() {
    let d = new Date();
    let local = d.getTime();
    let offset = d.getTimezoneOffset() * 60000;
    let localUtc = new Date().getTimezoneOffset() / 60;
    let utcTime;
    if (localUtc > 0) {
        utcTime = local - offset;
    } else {
        utcTime = local + offset;
    }
    let localTime = utcTime + 3600000 * Math.abs(8);
    return localTime;
}

function showHint(seconds) {
    const seconds1 = 1000;
    const minutes = seconds1 * 60;
    const hour = minutes * 60;
    const day = hour * 24;
    let iDay = parseInt(seconds / day);
    let d = seconds - iDay * day;
    let iHour = parseInt(d / hour);
    d = seconds - iDay * day - iHour * hour;
    let iMinutes = parseInt(d / minutes);
    d = seconds - iDay * day - iHour * hour - iMinutes * minutes;
    let iseconds = parseInt(d / seconds1);
    let showTime1 = "剩餘時間: ";
    if (iDay > 0) {
        showTime1 = showTime1 + iDay + "天";
    }
    if (iHour > 0) {
        showTime1 = showTime1 + iHour + "小時";
    }
    if (iMinutes > 0) {
        showTime1 = showTime1 + iMinutes + "分";
    }
    if (iseconds > 0) {
        showTime1 = showTime1 + iseconds + "秒";
    }
    if (seconds <= 1000) {
        showTime1 = "已過期";
    }
    let color = "#ffffff";
    if (seconds < 60000) {
        color = "#ff0000";
    }
    return "<span style='color:" + color + "'>" + showTime1 + "</span>";
}

class Timer extends Component {
    state = {
        seconds: 0,
        showTime: ''
    };

    tick = () => {
        const { seconds } = this.state;
        let showTime1 = showHint(seconds);

        this.setState({
            seconds: seconds - 1000,
            showTime: showTime1
        })
    }

    componentDidMount() {
        // 定时器，可以修改1000为自己想要的时间，
        const { product } = this.props;
        this.state.seconds = product.endTime - getBjTime();

        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        // 清除定时器
        clearInterval(this.interval);
        this.setState = () => false;
    }
    render() {
        const { product } = this.props;
        this.state.seconds = product.endTime - getBjTime();
        this.state.showTime = showHint(this.state.seconds);

        return (
            <div style={{ fontSize: '1.25em' }} dangerouslySetInnerHTML={{ __html: this.state.showTime }}></div>
        )
    }
}

class PaymentAgencyList extends Component {
    render() {
        const { currency, product, followMode, hupaystatus, mode } = this.props;
        const price = product.prices[currency];

        let columns1 = "3";
        let paddingLeft1 = "1em";
        let paddingLeft2 = "0em";
        let padding1 = "0px";
        let width1 = "90%";
        if (mode === "LANDSCAPE") {
            columns1 = "3";
            paddingLeft1 = "1em";
            if ((product.isNoodle1) || (product.isNoodle2) || (product.xh)) {
                padding1 = "1px";
            } else {
                padding1 = "1em";
            }
            width1 = "90%";
            paddingLeft2 = "0em";
        }
        return (
            <React.Fragment>
                <Grid columns={columns1}
                    style={{
                        marginLeft: "5%",
                        width: `${width1}`, padding: `${padding1}`,
                        paddingLeft: `${paddingLeft2}`,
                        paddingTop: `${(mode === "LANDSCAPE") ? "20px" : "10px"}`,
                        paddingBottom: "10px",
                        textAlign: "center"
                    }}
                >
                    {
                        getValidButtonData(currency, price, hupaystatus).map((data, idx) => {
                            let disabled = data.outOfRange(price, hupaystatus, currency);
                            let display = "";
                            if (disabled === true) {
                                display = "none";
                            }
                            // if (((product.productId === "67dab3be-2021-4e70-0812-895acc63c002") || (product.productId === "67dab3be-2021-4e70-0812-895acc63c022") || (product.productId === "67dab3be-2021-4e70-1014-895acc63c002") || (product.productId === "67dab3be-2021-4e70-1014-895acc63c003")) && (data.provider === "cnalipay")) {
                            //     disabled = true;
                            //     display = "none";
                            // } else
                            if ((product.closeprovider) && ((product.closeprovider.includes(data.provider) || product.closeprovider.includes(data.paid)))) {
                                disabled = true;
                                display = "none";
                            } else
                                if ((product.showprovider) && (currency === "CNY") && (data.provider === "bbmslpay")) {
                                    disabled = true;
                                    display = "none";
                                } else
                                    if ((product.showprovider) && (!product.showprovider.includes(data.provider) && !product.showprovider.includes(data.paid))) {
                                        disabled = true;
                                        display = "none";
                                    } else if (followMode === 1) {
                                        // if ((data.provider === "asiaunionpay") || (data.paid === "BNK82204") || (data.provider === "hkalipay")){
                                        if ((data.provider === "asiaunionpay") || (data.paid === "BNK82204") || (data.provider === "paydollar") || (data.provider === "allpayx")) {
                                            disabled = true;
                                            display = "none";
                                        }
                                    } else if (followMode === 2) {
                                        // if ((data.provider !== "asiaunionpay") && (data.paid !== "BNK82204") && (data.provider !== "hkalipay")){
                                        if ((data.provider !== "asiaunionpay") && (data.paid !== "BNK82204") && (data.provider !== "paydollar") && (data.provider !== "allpayx")) {
                                            disabled = true;
                                            display = "none";
                                        }
                                    }

                            return (<GridColumn key={idx}
                                style={{
                                    paddingLeft: `${paddingLeft1}`,
                                    paddingRight: `${paddingLeft1}`,
                                    paddingTop: `${paddingLeft1}`,
                                    paddingBottom: `${paddingLeft1}`,
                                    display: `${display}`
                                }}
                            >
                                <CreditPurchaseButton disabled={disabled}
                                    image={data.image} currency={data.currency}
                                    product={product}
                                    provider={data.provider}
                                    paid={data.paid} />
                            </GridColumn>);
                        }

                        )
                    }
                </Grid>
            </React.Fragment>
        );
    }
}