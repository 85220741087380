import React, {Component} from "react";
import {Button, Dimmer, Header, Image, Loader, Modal} from "semantic-ui-react";


export class QRModal2 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    onOpen() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Modal
                size={"mini"}
                open={this.props.open}
                onMount={this.onOpen.bind(this)}
                style={{width:"90vw"}}
            >
                <Modal.Content image>
                    <Modal.Description>
                        <Header style={{width:"100%",textAlign:"left", fontSize:"1.1rem"}}>{this.props.message}</Header>
                        <p>
                        </p>
                    </Modal.Description>
                    <Modal.Actions style={{textAlign:"center"}}>
                        <Button
                            style={{background:"green"}}
                            negative
                            onClick={() => {
                                this.props.onClose();
                            }}>好的</Button>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        );
    }
}