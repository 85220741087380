import React, { Component } from "react";
import PropTypes from "prop-types";
import CreditPurchaseButtonP99 from "./credit-purchase-button-p99";
import CreditPurchaseButtonGash from "./credit-purchase-button-gash";
import CreditPurchaseButtonTwGash from "./credit-purchase-button-twgash";
import CreditPurchaseButtonJpay from "./credit-purchase-button-jpay";
import CreditPurchaseButtonHupay from "./credit-purchase-button-hupay";
import CreditPurchaseButtonHupay2 from "./credit-purchase-button-hupay2";
import CreditPurchaseButtonHupay3 from "./credit-purchase-button-hupay3";
import CreditPurchaseButtonHupay4 from "./credit-purchase-button-hupay4";
import CreditPurchaseButtonHupay5 from "./credit-purchase-button-hupay5";
import CreditPurchaseButtonHupay6 from "./credit-purchase-button-hupay6";
import CreditPurchaseButtonGhpgpay from "./credit-purchase-button-ghpgpay";
import CreditPurchaseButtonMtpay from "./credit-purchase-button-mtpay";
import CreditPurchaseButtonKtpay from "./credit-purchase-button-ktpay";
import CreditPurchaseButtonG3pay from "./credit-purchase-button-g3pay";
import CreditPurchaseButtonG3alipay from "./credit-purchase-button-g3alipay";
import CreditPurchaseButtonAntpay from "./credit-purchase-button-antpay";
import CreditPurchaseButtonAntpay2 from "./credit-purchase-button-antpay2";
import CreditPurchaseButtonAntpay3 from "./credit-purchase-button-antpay3";
import CreditPurchaseButtonAntpay4 from "./credit-purchase-button-antpay4";
import CreditPurchaseButtonBbmslpay from "./credit-purchase-button-bbmslpay";
import CreditPurchaseButtonGeoswiftpay from "./credit-purchase-button-geoswiftpay";
import CreditPurchaseButtonGeounionpay from "./credit-purchase-button-geounionpay";
import CreditPurchaseButtonHeepay from "./credit-purchase-button-heepay";
import CreditPurchaseButtonHkalipay from "./credit-purchase-button-hkalipay";
import CreditPurchaseButtonHkalipay2 from "./credit-purchase-button-hkalipay2";
import CreditPurchaseButtonWechatpay from "./credit-purchase-button-wechatpay";
import CreditPurchaseButtonFakapay from "./credit-purchase-button-fakapay";
import CreditPurchaseButtonAllpayx from "./credit-purchase-button-allpayx";
import CreditPurchaseButtonCnalipay from "./credit-purchase-button-cnalipay";
import CreditPurchaseButtonCnalipay2 from "./credit-purchase-button-cnalipay2";
import CreditPurchaseButtonUnipockpay from "./credit-purchase-button-unipockpay";
import CreditPurchaseButtonCnalipayPrivate from "./credit-purchase-button-cnalipayprivate";
import CreditPurchaseButtonCnalipayPrivateYi from "./credit-purchase-button-cnalipayprivateyi";
import CreditPurchaseButtonCnalipayPrivateQx from "./credit-purchase-button-cnalipayprivateqx";
import CreditPurchaseButtonspglobalpay from "./credit-purchase-button-spglobalpay";
import CreditPurchaseButtonSicpay from "./credit-purchase-button-sicpay";
import CreditPurchaseButtonHipay from "./credit-purchase-button-hipay";
import CreditPurchaseButtonAsiaunionpay from "./credit-purchase-button-asiaunionpay";
import CreditPurchaseButtonPaydollar from "./credit-purchase-button-paydollar";
import CreditPurchaseButtonAsiaWechat from "./credit-purchase-button-asiawechat";

export class CreditPurchaseButton extends Component {
    render() {
        switch (this.props.provider) {
            case "p99":
                return <CreditPurchaseButtonP99 image={this.props.image}
                    product={this.props.product}
                    currency={this.props.currency}
                    disabled={this.props.disabled}
                    paid={this.props.paid} />;
            case "gash":
                return <CreditPurchaseButtonGash image={this.props.image}
                    product={this.props.product}
                    currency={this.props.currency}
                    disabled={this.props.disabled}
                    paid={this.props.paid} />;
            case "twgash":
                return <CreditPurchaseButtonTwGash image={this.props.image}
                    product={this.props.product}
                    currency={this.props.currency}
                    disabled={this.props.disabled}
                    paid={this.props.paid} />;
            case "jpay":
                return <CreditPurchaseButtonJpay image={this.props.image}
                    product={this.props.product}
                    currency={this.props.currency}
                    disabled={this.props.disabled}
                    payType={this.props.paid} />;
            case "hupay":
                return <CreditPurchaseButtonHupay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "hupay2":
                return <CreditPurchaseButtonHupay2 image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "hupay3":
                return <CreditPurchaseButtonHupay3 image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "hupay4":
                return <CreditPurchaseButtonHupay4 image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "hupay5":
                return <CreditPurchaseButtonHupay5 image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "hupay6":
                return <CreditPurchaseButtonHupay6 image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "ghpgpay":
                return <CreditPurchaseButtonGhpgpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "mtpay":
                return <CreditPurchaseButtonMtpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "ktpay":
                return <CreditPurchaseButtonKtpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "g3pay":
                return <CreditPurchaseButtonG3pay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "g3alipay":
                return <CreditPurchaseButtonG3alipay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "geoswiftpay":
                return <CreditPurchaseButtonGeoswiftpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "geounionpay":
                return <CreditPurchaseButtonGeounionpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "antpay":
                return <CreditPurchaseButtonAntpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "antpay2":
                return <CreditPurchaseButtonAntpay2 image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "antpay3":
                return <CreditPurchaseButtonAntpay3 image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "antpay4":
                return <CreditPurchaseButtonAntpay4 image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "bbmslpay":
                return <CreditPurchaseButtonBbmslpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "asiaunionpay":
                return <CreditPurchaseButtonAsiaunionpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled}
                    paid={this.props.paid} />;
            case "paydollar":
                return <CreditPurchaseButtonPaydollar image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled}
                    paid={this.props.paid} />;
            case "asiawechat":
                return <CreditPurchaseButtonAsiaWechat image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled}
                    paid={this.props.paid} />;
            case "sicpay":
                return <CreditPurchaseButtonSicpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled}
                    paid={this.props.paid} />;
            case "hipay":
                return <CreditPurchaseButtonHipay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled}
                    paid={this.props.paid} />;
            case "heepay":
                return <CreditPurchaseButtonHeepay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;

            case "hkalipay":
                return <CreditPurchaseButtonHkalipay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "hkalipay2":
                return <CreditPurchaseButtonHkalipay2 image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "allpayx":
                return <CreditPurchaseButtonAllpayx image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "fakapay":
                return <CreditPurchaseButtonFakapay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;

            case "cnalipay":
                return <CreditPurchaseButtonCnalipay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "cnalipay":
                return <CreditPurchaseButtonCnalipay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "cnalipay2":
                return <CreditPurchaseButtonCnalipay2 image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "unipockpay":
                return <CreditPurchaseButtonUnipockpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "cnalipayprivate":
                return <CreditPurchaseButtonCnalipayPrivate image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "cnalipayprivateyi":
                return <CreditPurchaseButtonCnalipayPrivateYi image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "cnalipayprivateqx":
                return <CreditPurchaseButtonCnalipayPrivateQx image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "spglobalpay":
                return <CreditPurchaseButtonspglobalpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            case "wechatpay":
                return <CreditPurchaseButtonWechatpay image={this.props.image}
                    product={this.props.product}
                    paymentAgency={this.props.paid}
                    disabled={this.props.disabled} />;
            default:
                return null;
        }
    }
}

CreditPurchaseButton.propTypes = {
    image: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    provider: PropTypes.string.isRequired,
    paid: PropTypes.any.isRequired
};