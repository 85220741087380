import React, {Component} from "react";
import {navigate} from "../../../../../../.cache/gatsby-browser-entry";
import KaisakuApi from "../../../../../controllers/kaisaku-api";
import {Button, Dimmer, Header, Image, Loader, Modal} from "semantic-ui-react";
import {secondsToString} from "../../../../../lib/time-util";

export class QRModal2 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    onOpen() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Modal
                size={"mini"}
                open={this.props.open}
                onMount={this.onOpen.bind(this)}
                style={{width:"90vw"}}
            >
                <Modal.Content image>
                    <Modal.Description>
                        <Header style={{width:"100%",textAlign:"left", fontSize:"1.1rem"}}>親，高額支付寶充值（1000元以上）已超單日上限，請選擇其他支付方式或於00:00重置後再試~</Header>
                        <p>
                        </p>
                    </Modal.Description>
                    <Modal.Actions style={{textAlign:"center"}}>
                        <Button
                            style={{background:"green"}}
                            negative
                            onClick={() => {
                                this.props.onClose();
                            }}>確認</Button>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        );
    }
}