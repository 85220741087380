import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";
import {Image, Loader, Modal} from "semantic-ui-react";
import PAButtonBase from "./pa-button-base";
import {getUA} from "react-device-detect";
import cookie from 'react-cookies';
import {QRModal} from "./spglobalpay/lib/qrmodal";

class CreditPurchaseButtonSpglobalpay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            open: false,
            qrCodeUrl: "",
            transactionId: "",
        };
    }

    onClose() {
        console.log("close");
        this.setState({
            loading: false,
            open: false
        });
    }

    handleClick() {
        if(this.props.disabled) {
            return;
        }
        this.setState({
            loading: true
        });
        new KaisakuUserApi(this.props.session).createTransactionSpglobalpay(this.props.product.productId, this.props.paymentAgency, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
            if(res.status === 200) {
                console.log(res);
                const { payUrl, paymentAgency, transactionId} = res.data;
                if (payUrl){
                    console.log(payUrl);
                    console.log(paymentAgency);
                    // const htmldata = JSON.parse(result).htmldata;
                    // let htmldata1 = decodeURIComponent(htmldata);
                    // htmldata1 = window.atob(htmldata1);
                    // console.log(htmldata1);
                    if ((paymentAgency === "WEBALIPAYHK") || (paymentAgency === "WEBALIPAYCN")){
                        const url_qrcode = payUrl;
                        this.setState({
                            loading: false,
                            open: true,
                            qrCodeUrl: url_qrcode,
                            transactionId
                        });
                    }else{
                        let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
                        // cookie.save("_action1", "toalipay", {
                        //     path:"/",
                        //     expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
                        // });
                        // cookie.save("_alipaytransactionId", transactionId, {
                        //     path:"/",
                        //     expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
                        // });
                        window.location.href = payUrl;
                    }
                }else{
                    navigate(`/credit-purchase-result/?success=${false}&message=${encodeURI('請稍候重試交易~')}`)
                }
            } else {
                navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
            }
        });
    }

    render() {
        const gameId1 = "aac53ce9-f03c-4e71-953b-df9ec022a837";
        const gameId2 = "aac53ce9-f03c-4e71-953b-df9ec022a837";
        const gameId3 = "b15402f9-6bca-4c17-abed-5ba00f7dd0c7";
        const gameId4 = "de7679d9-c5b5-47ae-84f5-ad96be7d612e";
        const gameId5 = "f552d32d-f73a-46c6-ba20-42098ed27c1c";
        const gameId6 = "87cd854c-f5f4-4018-a57d-6d49007931db";

        const gameId = this.props.gameId;
        let buttondisabled = this.props.disabled;
        if (((gameId1 === gameId) || (gameId2 === gameId) || (gameId3 === gameId) || (gameId4 === gameId)
            || (gameId5 === gameId) || (gameId6 === gameId))){;
            buttondisabled = true;
        }
        return (
            <Fragment>
                <Modal basic open={this.state.loading} size='small'>
                    <Modal.Content>
                        <Loader/>
                    </Modal.Content>
                </Modal>
                <Image
                    price={this.props.product.prices[this.props.currency]}
                    src={this.props.image}
                    disabled={buttondisabled}
                    hidden={buttondisabled}
                    onClick={this.handleClick.bind(this)}/>
                <QRModal transactionId={this.state.transactionId} qrCodeUrl={this.state.qrCodeUrl}
                         onClose={this.onClose.bind(this)} open={this.state.open}/>
            </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonSpglobalpay);

CreditPurchaseButtonSpglobalpay.propTypes = {
    image: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};