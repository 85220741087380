import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";
import {Image, Loader, Modal} from "semantic-ui-react";
import PAButtonBase from "./pa-button-base";
import {getUA} from "react-device-detect";
import cookie from 'react-cookies';

class CreditPurchaseButtonUnipockpay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            open: false,
            qrCodeUrl: "",
            transactionId: "",
        };
    }

    onClose() {
        this.setState({
            open: false
        });
    }

    handleClick() {
        if(this.props.disabled) {
            return;
        }
        this.setState({
            loading: true
        });
        new KaisakuUserApi(this.props.session).createTransactionUnipockpay(this.props.product.productId, this.props.paymentAgency, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
            if(res.status === 200) {
                const { url, transactionId} = res.data;
                if (url){
                    cookie.save("_gourlparams", "", {
                        path:"/"
                    });
                    let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
                    // cookie.save("_action1", "toalipay", {
                    //     path:"/",
                    //     expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
                    // });
                    // cookie.save("_alipaytransactionId", transactionId, {
                    //     path:"/",
                    //     expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
                    // });
                    // console.log("789");
                    // console.log(cookie.load("_action"));
                    window.location.href = url;
                    // document.open();
                    // document.write(result);
                    // document.close();
                }else{
                    navigate(`/credit-purchase-result/?success=${false}&message=${encodeURI('請稍候重試交易~')}`)
                }
            } else {
                navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
            }
        });
    }

    render() {
        const gameId1 = "aac53ce9-f03c-4e71-953b-df9ec022a837";
        const gameId2 = "aac53ce9-f03c-4e71-953b-df9ec022a837";
        const gameId3 = "b15402f9-6bca-4c17-abed-5ba00f7dd0c7";
        const gameId4 = "de7679d9-c5b5-47ae-84f5-ad96be7d612e";
        const gameId5 = "f552d32d-f73a-46c6-ba20-42098ed27c1c";
        const gameId6 = "87cd854c-f5f4-4018-a57d-6d49007931db";

        const gameId = this.props.gameId;
        let buttondisabled = this.props.disabled;
        if (((gameId1 === gameId) || (gameId2 === gameId) || (gameId3 === gameId) || (gameId4 === gameId)
            || (gameId5 === gameId) || (gameId6 === gameId))){;
            buttondisabled = true;
        }
        console.log(buttondisabled);
        return (
            <Fragment>
                <PAButtonBase price={this.props.product.prices[this.props.currency]}
                              disabled={buttondisabled}
                              hidden={buttondisabled}
                              src={this.props.image}
                              onClick={this.handleClick.bind(this)}/>
            </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonUnipockpay);

CreditPurchaseButtonUnipockpay.propTypes = {
    image: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};