import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import PAButtonBase from "./pa-button-base";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";

class CreditPurchaseButtonJpay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            clicked: false,
            pageData: ""
        };
    }

    handleClick() {
        if(this.state.clicked) {
            console.log("Already clicked!");
            return;
        }
        this.setState({
            clicked: true
        });
        new KaisakuUserApi(this.props.session).createTransactionJpay(this.props.product.productId, this.props.currency, this.props.payType, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
            if(res.status === 200) {
                document.open();
                document.write(res.data);
                document.close();
            } else {
                navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
            }
        });
    }

    render() {
        return (
            <Fragment>
                <PAButtonBase price={this.props.product.prices[this.props.currency]}
                              src={this.props.image}
                              disabled={this.props.disabled}
                              hidden={this.props.disabled}
                              onClick={this.handleClick.bind(this)}/>
            </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonJpay);

CreditPurchaseButtonJpay.propTypes = {
    image: PropTypes.string.isRequired,
    payType: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired
};