import React, {Component} from "react";
import View from "./package-purchase-form-view.js";
import {connect} from "react-redux";
import {formatQuery} from "../../../controllers/utilities/string-utils";
import withLocation from "../../wrappers/with-location";
import KaisakuApi from "../../../controllers/kaisaku-api";
import {navigate} from "gatsby";
import {getProductTotal} from "../../../controllers/utilities/transaction-util";
import {hasValidButton} from "../../credit-purchase/pa-buttons/buttonData";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";

class PackagePurchaseForm extends Component {
    constructor(props, context) {
        super(props, context);

        const {currency} = formatQuery(props.location.search);
        this.currency = currency;

        this.state = {
            ready1: false,
            ready: false,
            update: false,
            products: [],
            error: ""
        };
        this.api = new KaisakuUserApi(this.props.session);
    }

    async componentDidMount() {
        let res = await this.api.getAllCreditProducts(this.currency);
        if(res.success) {
            if ((this.currency !== "CNY") && (this.currency !== "RMB") && (this.currency !== "HKD")){
                res.products = res.products.filter(o => o.productId !== "67dab3be-2021-4e70-1005-895acc63c001"
                // && o.productId !== "67dab3be-2021-4e70-1019-895acc63c001" && o.productId !== "67dab3be-2021-4e70-1019-895acc63c002"
                // && o.productId !== "67dab3be-2021-4e70-1019-895acc63c003" && o.productId !== "67dab3be-2021-4e70-1019-895acc63c004"
                && o.productId !== "67dab3be-2021-4e70-1019-895acc63c005"

                // && o.productId !== "67dab3be-2022-0060-0225-895acc63c001"
                // && o.productId !== "67dab3be-2022-6011-0225-895acc63c001" && o.productId !== "67dab3be-2022-6011-0225-895acc63c002"
                // && o.productId !== "67dab3be-2022-6011-0225-895acc63c003" 
                // && o.productId !== "67dab3be-2022-6111-0225-895acc63c001" && o.productId !== "67dab3be-2022-6111-0225-895acc63c002"
                // && o.productId !== "67dab3be-2022-6111-0225-895acc63c003" 
                );
            }
            if ((this.currency === "CNY") || (this.currency === "RMB")){
                res.products = res.products.filter(o => o.productId !== "67dab3be-2022-6666-0225-895acc63c001"
                && o.productId !== "67dab3be-2022-6666-0225-895acc63c002" && o.productId !== "67dab3be-2022-6666-0225-895acc63c003"
                );
            }
            this.setState({
                ready1: true,
                update: true,
                products: res.products,
                hasEvent: res.events.length > 0
            });
        } else {
            this.setState({
                ready1: true,
                update: true,
                error: res.message
            });
        }

        // this.state.expireTimer = setTimeout(() => {
        //     clearInterval(this.state.expireTimer);
        //     console.log("cccc");
        //     this.setState({
        //         ready: true
        //     });
            
        // }, 1000);
        // console.log("componentDidMount");
    }

    componentDidUpdate(){
        console.log("componentDidUpdate");
        if ((this.state.ready1) && (this.state.update)){
            this.state.expireTimer = setTimeout(() => {
            clearInterval(this.state.expireTimer);
            console.log("cccc");
            this.setState({
                ready: true,
                update: false
            });
            
        }, 500);
        }
    }

    onSelect(productId) {
        navigate(`/payment-providers/?productId=${productId}&currency=${this.currency}`);
    }

    onCancel() {
        navigate("/confirm-purchase");
    }

    render() {
        let missingGold = 0;
        // 获取数据
        const filteredProducts = this.state.products.filter(p => hasValidButton(this.currency, p.prices[this.currency]));
        if(this.props.transaction) {
            const total = getProductTotal(this.props.transaction);
            missingGold = total - this.props.transaction.balance;
        }
        return (
            <View ready={this.state.ready} hasEvent={this.state.hasEvent}
                  products={filteredProducts} missingGold={missingGold} currency={this.currency}
                  onSelect={this.onSelect.bind(this)} onCancel={this.onCancel.bind(this)}
                  mode={this.props.mode}
            />
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(withLocation(PackagePurchaseForm));