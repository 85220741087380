import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";
import {Image, Loader, Modal} from "semantic-ui-react";
import {QRModal} from "./ghpg/lib/qrmodal";

class CreditPurchaseButtonGhpgpay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            open: false,
            qrCodeUrl: "",
            transactionId: ""
        };
    }

    onClose() {
        this.setState({
            open: false
        });
    }

     handleClick() {
        if(this.props.disabled) {
            return;
        }
        this.setState({
            loading: true
        });
        new KaisakuUserApi(this.props.session).createTransactionGhpgpayTry3(this.props.product.productId, this.props.paymentAgency, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
            if(res.status === 200) {
                console.log(res.data);
                const {resCode, resMsg, response, transactionId} = res.data;
                if (resCode === "000"){
                    console.log(response);
                    const url = "";
                    let url_qrcode = Buffer.from(response, 'base64').toString('ascii');
                    // console.log(JSON.parse(url_qrcode));
                    // console.log(JSON.parse(url_qrcode).resultQRImage);

                    // if (JSON.parse(url_qrcode).resultQRImage)
                    //     url_qrcode = JSON.parse(url_qrcode).resultQRImage;
                    console.log(url_qrcode);
                    const url_qrcodeurl = url_qrcode;
                    //
                    // // http://learn.urtoppay.com/image/U3VVempKWGxpQ1pkS01JaENtMDcvUT09.jpg
                    // // http://learn.mtpay8888.com/pay/OVZTMmV4cFVYcmNrVmZsR0Q4dDlKZz09
                    // url_qrcode = url_qrcode.replace("http://", "https://");
                    // url_qrcode = url_qrcode.replace("/image/", "/pay/");
                    // url_qrcode = url_qrcode.replace(".jpg", "");
                    // console.log(url_qrcode);
                    try {
                        const obj = JSON.parse(url_qrcode);
                        url_qrcode = obj.resultUrl;
                        const code = obj.code;
                        if (code !== "0000") {
                            navigate(`credit-purchase-result/?success=${false}&message=${encodeURI('請稍候重試交易~')}`);
                        } else {
                            this.setState({
                                loading: false,
                                open: true,
                                url,
                                qrCodeUrl: url_qrcode,
                                qrCodeUrl2: url_qrcodeurl,
                                transactionId
                            });
                        }
                    }catch(err) {
                        console.log(err);
                        navigate(`credit-purchase-result/?success=${false}&message=${encodeURI('請稍候重試交易~')}`);
                    }
                }else{
                    navigate(`credit-purchase-result/?success=${false}&message=${res.data.msg}`);
                }

            } else {
                navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
            }
        });
    }

    render() {
        return (
            <Fragment>
                <Modal basic open={this.state.loading} size='small'>
                    <Modal.Content>
                        <Loader/>
                    </Modal.Content>
                </Modal>
                <Image disabled={this.props.disabled}
                       hidden={this.props.disabled}
                       src={this.props.image}
                       onClick={this.handleClick.bind(this)}/>
                <QRModal transactionId={this.state.transactionId} qrCodeUrl={this.state.qrCodeUrl} qrCodeUrl2={this.state.qrCodeUrl2}
                         onClose={this.onClose.bind(this)} open={this.state.open}/>
            </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonGhpgpay);

CreditPurchaseButtonGhpgpay.propTypes = {
    image: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};