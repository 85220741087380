import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";
import {Image, Loader, Modal} from "semantic-ui-react";
import PAButtonBase from "./pa-button-base";

class CreditPurchaseButtonSicpay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            open: false,
            qrCodeUrl: "",
            transactionId: ""
        };
    }

    onClose() {
        this.setState({
            open: false
        });
    }

     handleClick() {
         if(this.props.disabled) {
             return;
         }
         this.setState({
             loading: true
         });
         new KaisakuUserApi(this.props.session).createTransactionSicpay(this.props.product.productId, this.props.paymentAgency, this.props.paid, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
             if(res.status === 200) {
                 const { success1, pay_url, error_code, message, transactionId} = res.data;
                 if (error_code === 0) {
                     console.log(pay_url);
                     window.location.href = pay_url;
                 }else{
                     navigate(`/credit-purchase-result/?success=${false}&message=${message}`)
                 }
             } else {
                 navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
             }
         });
    }

    render() {
        return (
            <Fragment>
                <PAButtonBase price={this.props.product.prices[this.props.currency]}
                              src={this.props.image}
                              disabled={this.props.disabled}
                              hidden={this.props.disabled}
                              onClick={this.handleClick.bind(this)}/>
            </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonSicpay);

CreditPurchaseButtonSicpay.propTypes = {
    image: PropTypes.string.isRequired,
    paid: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};