import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import PropTypes from "prop-types";
import {Image, Loader, Modal} from "semantic-ui-react";
import PAButtonBase from "./pa-button-base";
import {QRModal} from "./hipay/lib/qrmodal";

class CreditPurchaseButtonHipay extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            open: false,
            qrCodeUrl: "",
            transactionId: ""
        };
    }

    onClose() {
        this.setState({
            open: false
        });
    }

     handleClick() {
         if(this.props.disabled) {
             return;
         }
         this.setState({
             loading: true
         });
         new KaisakuUserApi(this.props.session).createTransactionHipay(this.props.product.productId, this.props.paymentAgency, this.props.paid, this.props.gameId, this.props.attributionId, this.props.transactionId).then(res => {
             if(res.status === 200) {
                 const { success1, pay_url, error_code, message, transactionId} = res.data;
                 if (error_code === 0) {
                     console.log(pay_url);
                     if (this.props.paid === "wapay_wechat"){
                         this.setState({
                             loading: false,
                             open: true,
                             qrCodeUrl: pay_url,
                             transactionId
                         });
                     }else{
                         window.location.href = pay_url;
                     }
                 }else{
                     navigate(`/credit-purchase-result/?success=${false}&message=${message}`)
                 }
             } else {
                 navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
             }
         });
    }

    render() {
        return (
            <Fragment>
                <Modal basic open={this.state.loading} size='small'>
                    <Modal.Content>
                        <Loader/>
                    </Modal.Content>
                </Modal>
                <Image
                    price={this.props.product.prices[this.props.currency]}
                    disabled={this.props.disabled}
                       hidden={this.props.disabled}
                       src={this.props.image}
                       onClick={this.handleClick.bind(this)}/>
                <QRModal transactionId={this.state.transactionId} qrCodeUrl={this.state.qrCodeUrl}
                         onClose={this.onClose.bind(this)} open={this.state.open}/>
            </Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(CreditPurchaseButtonHipay);

CreditPurchaseButtonHipay.propTypes = {
    image: PropTypes.string.isRequired,
    paid: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    paymentAgency: PropTypes.string.isRequired
};